import React, {useState, useEffect} from 'react';
import Review from './Review';
import './Reviews.css';
import {Link} from 'react-router-dom';

const Reviews = (props) => {
    //    const [artistID, setArtistID] = useState("");

    useEffect(() => {
        //        setArtistID(props.artist_id)
    }, [props.reviews])

    return (
        <div className="ReviewContainer">
            <span className="reviews-title">Notes</span>
            <hr/>
            {
                props.reviews.length > 0 ?
                    props.reviews.map((elt) => {
                        return (
                            <Review review={elt} key={elt.id} {...props} />
                        )
                    }) : (<p>{"Il n'y a pas encore de notes pour cette célébrité"}</p>)
            }
            <br/>
            {/* <Link className="linkToReviewsList" to={`/reviews/${artistID}`} >
    Lire les autres reviews
    </Link> */}
        </div>
    );
};

export default Reviews;
