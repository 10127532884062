import React from 'react';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const CardBlogHeader = props => {

    const {iconClass, preHeading, preHeadingspan, heading, headingText, headingTexttwo} = props.initData.repos;

    // heading.split('/').join('-');

    return (
        <Grid container>
            <Grid xs={12}>
                <div className="section-header-container">
                    <div className="section-header-container2">
                        { /* Section Heading */}
                        <div className="section-heading text-center">
                    <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i className={iconClass}/>
                        <span className="text-primary"> {preHeading} </span>
                        {preHeadingspan}
                    </span>
                            <h2 className="text-capitalize">
                                {heading}

                            </h2>
                            <p className="d-none d-sm-block mt-4"> {headingText}  </p>
                            <p className="d-block d-sm-none mt-4"> {headingTexttwo} </p>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid xs={12}>
                <a href={`/peoples/${heading.split('/').join('-').toLowerCase()}`}>
                    <p className="mr-3 float-right font-weight-bold text-sm">
                        <i className="txt-see-more text-sm">Voir Plus</i> <i className="fa fa-chevron-right">{""}</i>
                    </p>
                </a>
            </Grid>
        </Grid>
    );
};

export default CardBlogHeader;
