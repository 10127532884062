import React from "react";
import { Link } from "react-router-dom";
import { authLogout } from "../../config/auth-firebase";

const ModeConnected = () => {
	return (
		<li style={{ marginTop: "-15px" }}>
			{" "}
			<Link to="#">
				<span>
					<i
						className="fas fa-bars burger"
						style={{ color: "#fff", fontSize: 28 }}
					></i>
				</span>
			</Link>
			<ul className="dropdown-menu">
				<li>
					<Link className="dropdown-item" to="/">
						Accueil
					</Link>
				</li>
				<li>
					<Link className="dropdown-item" to="/my-account">
						Mon profil
					</Link>
				</li>
				{/*<li>
					<Link className="dropdown-item" to="/requests">
						Mes demandes
					</Link>
				</li>*/}
				<li>
					<Link className="dropdown-item" to="/favorite">
						Mes favories
					</Link>
				</li>

				{/*<li>
					<Link className="dropdown-item" to="/forgot">
						{" "}
						Paramètres
					</Link>
				</li>*/}

				<li>
					<Link
						className="dropdown-item"
						to="/login"
						onClick={authLogout}
					>
						{" "}
						Déconnecter
					</Link>
				</li>
			</ul>
		</li>
	);
};

export default ModeConnected;
