const data = [    
    {
        title: "sports",
        iconClass: "far fa-lightbulb text-primary mr-1",
        preHeading: "",
        preHeadingspan: "",
        heading: "Sports",
        headingText: "",
        headingTexttwo: ""
    },
    {
        title: "musique",
        iconClass: "far fa-lightbulb text-primary mr-1",
        preHeading: "",
        preHeadingspan: "",
        heading: "musique",
        headingText: "",
        headingTexttwo: ""
    },
    {
        title: "instagram",
        iconClass: "far fa-lightbulb text-primary mr-1",
        preHeading: "",
        preHeadingspan: "",
        heading: "Instagram",
        headingText: "",
        headingTexttwo: ""
    },
    {
        title:"acteurs/actrices", 
        iconClass: "far fa-lightbulb text-primary mr-1",
        preHeading: "",
        preHeadingspan: "",
        heading: "Acteurs/Actrices",
        headingText: "",
        headingTexttwo: ""
    },
    {
        title: "youtube",
        iconClass: "far fa-lightbulb text-primary mr-1",
        preHeading: "",
        preHeadingspan: "",
        heading: "Youtube",
        headingText: "",
        headingTexttwo: ""
    }    
]

export {data};