/* eslint-disable default-case */
import { LoginConsts } from "../components/Accounts/authentication/login-consts";
import db from "./../config/auth-firebase";
import userService from "./users-service";

const requestsRef = db.collection("requests");

const getAll = () => {
	/* var result = [];
    requestsRef//.where('category', '==', "acteurs/actrices")
    .get()
    .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) =>
        result.push({ id: doc.id, value: doc.data() })
        );
      });
  return result; */
	let resultLocal = [];
	try {
		return new Promise((resolve) => {
			// Get data from FireStore database
			var user = JSON.parse(
				window.localStorage.getItem(LoginConsts.USERNAME)
			);
			resultLocal = requestsRef
				.where("userID", "==", user.uid)
				.get()
				.then((querySnapshot) => {
					let result = [];
					querySnapshot.docs.forEach((doc) => {
						result.push({ id: doc.id, value: doc.data() });
					});
					return result;
				})
				.catch((error) => console.log(error));
			setTimeout(() => {
				resolve(resultLocal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
};

const create = (data) => {
	try {
		var result;
		return new Promise((resolve) => {
			// Get data from FireStore database
			result = requestsRef
				.add(data)
				.then((docRef) => {
					console.log("Document written with ID: ", docRef.id);
					return docRef.id;
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
            setTimeout(resolve, 500);
			/* setTimeout(() => {
				resolve(result);
			}, 2000); */
			resolve(result);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
};

const update = (id, value) => {
	return db.doc(id).update(value);
};

const remove = (id) => {
	return db.doc(id).delete();
};

const requestsService = {
	getAll,
	create,
	update,
	remove,
};

export default requestsService;
