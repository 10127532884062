import React, { useState, useEffect } from "react";
import Header from "../HeaderSection1/Header";
import Footer from "../FooterSection/Footer";
import {
  auth,
  authWithGoogle,
  authWithFacebook,
} from "./../../config/auth-firebase";
import { useAlert } from "react-alert";
import userService from '../../services/users-service';
import { AuthenticationService } from './authentication/authentication-service';
import { Link } from 'react-router-dom';

const initDataLoad = {
  heading: "Bienvenue!",
  content:
    "Quand offrir les mêmes vieux cadeaux, jouets ou fleurs devient trop ennuyeux, vous pouvez offrir à vos proches une surprise qu'ils n'oublieront jamais.  Que ce soit pour une occasion spéciale, ou pas du tout, Oulo est le cadeau le plus mémorable pour vos proches… ou pour vous-même!  Chanteurs, influenceurs, acteurs, footballeurs, youtubeurs et bien d'autres sont là pour réaliser votre souhait",
  formHeading: "Se connecter",
  formContent:
    "Remplissez tous les champs afin que nous puissions obtenir des informations sur vous. Nous ne vous enverrons jamais de spam",
  formText: "Vous n'avez pas de compte?",
  btnText: "Se connecter",
  btnText_2: "S'enregistrer",
};

const Login = (props) => {
  const [initData, setInitData] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const alert = useAlert();
  // const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event) => {
    event.preventDefault();
    console.log("login building");

    auth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        console.log(response.user);
        AuthenticationService.authenticate(response.user);
        var promise = userService.getFavoritesByUserId();
        promise.then(dataResult => {
          // console.log(dataResult);
          console.log("result", dataResult);
          AuthenticationService.setFavouritesOfUserIds(dataResult);
          props.history.push('/');
        });
      })
      .catch((err) => {
        console.log("login error");
        console.log(err);
        alert.show(err.message, {
          title: err.code,
        });
      });
  };

  useEffect(() => {
    setInitData(initDataLoad);
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="homepage-5x accountsx inner-pages">
      <div className="main">
        <Header imageData={"/img/logo-white.png"} />
        <section
          id="home-auth"
          className="section welcome-area h-100vh bg-overlay d-flex align-items-center"
        >
          <div className="container">
            <div className="row">
              <Link to="/">
                <i
                  className="fa fa-arrow-left fa-lg fa-fw "
                  aria-hidden="true"
                  style={{ color: "black" }}
                ></i>{" "}
                <h5 className="para">Retour</h5>
              </Link>
            </div>
            <div className="row align-items-center justify-content-center">
              {/* Welcome Intro Start */}
              <div className="col-12 col-lg-7">
                <div className="welcome-intro">
                  <h1 className="text-black">{initData.heading}</h1>
                  <p className="text-black my-4">{initData.content}</p>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-5 positionl">
                {/* Contact Box */}
                <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg ">
                  {/* Contact Form */}
                    <div className="contact-top">
                      <h3 className="contact-title text-black">{initData.formHeading}</h3>
                      <h5 className="text-secondary fw-3 py-3 text-black" >
                        {initData.formContent}
                      </h5>
                    </div>
                    <div id="bodyDiv" className="row">
                      <button className="loginBtn loginBtn--facebook"
                        onClick={(event) => authWithFacebook()}
                      >
                        Login Facebook
                      </button>

                      <button className="loginBtn loginBtn--google"
                        onClick={(event) => authWithGoogle()}
                      >
                        Login Google
                      </button>
                    </div>
                    <div class="_18ajrTNfG7BRg-y2PDYD9X"><span class="_944rHfQa8HkvySxJQYhxo _3VGn4MnZlzhe_5zrohd1VS">OU</span></div>
                    <form id="contact-form">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-envelope-open" />
                                </span>
                              </div>
                              <input
                                type="email"
                                className="form-control"
                                name="userEmail"
                                value={email}
                                placeholder="E.g: email@xxx.com"
                                id="userEmail"
                                onChange={(event) => onChangeHandler(event)}
                                required="required"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-unlock-alt" />
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control"
                                name="userPassword"
                                value={password}
                                placeholder="Mot de passe"
                                id="userPassword"
                                onChange={(event) => onChangeHandler(event)}
                                required="required"
                              />
                            </div>
                          </div>
                        </div>

                          <div className="col-12">
                            <button
                              className="loginBtn loginBtn--mail"
                              onClick={(event) =>
                                signInWithEmailAndPasswordHandler(event)
                              }
                            >
                              {initData.btnText+"    "}
                            </button>
                          </div>
                      </div>
                    </form>
                  {/* <div className="contact-bottom">
                    <span className="d-inline-block mt-3">
                      By signing up, you accept our <Link to="/#">Terms</Link>{" "}
                      &amp; <Link to="/#">Privacy Policy</Link>
                    </span>
                  </div> */}

                <div className="col-12">
                  <span className="d-block pt-2 mt-4 border-top">
                    {initData.formText}{" "}
                    <Link to="/Signup">{initData.btnText_2}</Link>
                  </span>
                </div>
              </div>
          </div>
        </div>
      </div>
          {/* Shape Bottom */}
          {/* <div className="shape-bottom">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="shape-fill"
                fill="#FFFFFF"
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              />
            </svg>
          </div> */}
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
