import db from "../config/auth-firebase";
import {LoginConsts} from "../components/Accounts/authentication/login-consts";
import {ArtistConts} from "./ArtistConts";

const usersCOllection = db.collection("users");
const artistCollection = db.collection("artists");

const getAll = () => {
    var result = [];
    usersCOllection.get().then((querySnapshot) => {
        querySnapshot.docs.map((doc) =>
            result.push({id: doc.id, value: doc.data()})
        );
        console.log(result);
    });
    return result;
};

async function getArtistByFavoriteId() {
    // Traitement de la récupération
    const result = await getFavoritesByUserId();
    let artists = [];

    result.map(async (elt) => {
        // console.log("ID:", elt);

        var artist = await getArtistByDocId(elt);
        // console.log("Artist By Favorite ID:", artist);
        if (artist) {
            artists.push(artist);
        }

        // console.log("ID:", artists);
        // return  artist;
    });
    console.log("ID:", artists);
    window.localStorage.setItem(ArtistConts.ARTIST_Favourites, artists);
    // return await artists;
}

async function getArtistByDocId(docId) {
    var docRefa = artistCollection.doc(docId);

    // Traitement de la récupération
    return await docRefa
        .get()
        .then((doca) => {
            if (doca) {
                // console.log("Favorite data:", doca.data());
                return doca.data();
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
}

async function getFavoritesByUserId() {
    // Formater l'objet recupérer depusi la section
    var user = JSON.parse(window.localStorage.getItem(LoginConsts.USERNAME));

    return new Promise((resolve) => {
        // Récupérer le user par son ID depuis Firebase
        var docRef = usersCOllection.doc(user.uid);
        var favorites = [];

        // Traitement de la récupération
        favorites = docRef
            .get()
            .then((doc) => {
                if (doc) {
                    // console.log("Document data:", doc.data());
                    var docData = doc.data();
                    var result = [];
                    // console.log("docData data:", docData);
                    if (docData !== undefined) {
                        docData.favorites.forEach((elt) => {
                            result.push(elt);
                        });
                    }
                }
                // console.log(result);
                return result;
            })
            .catch((error) => {
                console.log("Error getting document:", error);
            });

        setTimeout(() => {
            //   console.log("fetched user");
            resolve(favorites);
        }, 1000);
    });
}

async function getRequestsByUserId() {
    // Formater l'objet recupérer depusi la section
    var user = JSON.parse(window.localStorage.getItem(LoginConsts.USERNAME));

    return new Promise((resolve) => {
        // Récupérer le user par son ID depuis Firebase
        var docRef = usersCOllection.doc(user.uid);
        var requests = [];

        // Traitement de la récupération
        requests = docRef
            .get()
            .then((doc) => {
                if (doc) {
                    // console.log("Document data:", doc.data());
                    var docData = doc.data();
                    var result = [];
                    console.log("docData data:", docData);
                    if (docData !== undefined) {
                        docData.requests.forEach((elt) => {
                            result.push(elt);
                        });
                    }
                }
                // console.log(result);
                return result;
            })
            .catch((error) => {
                console.log("Error getting document:", error);
            });

        setTimeout(() => {
            //   console.log("fetched user");
            resolve(requests);
        }, 1000);
    });
}

async function getUserByIdUnique(id) {
    // Récupérer le user par son ID depuis Firebase
    var docRef = usersCOllection.doc(id);

    // Traitement de la récupération
    return docRef
        .get()
        .then((doc) => {
            return doc.data();
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
}

const getUserById = (id, checked) => {
    var result = [];
    // Formater l'objet recupérer depusi la section
    var user = JSON.parse(window.localStorage.getItem(LoginConsts.USERNAME));
    console.log("getUserById - User-Service");
    console.log(id);

    // Récupérer le user par son ID depuis Firebase
    var docRef = usersCOllection.doc(user.uid);

    // Traitement de la récupération
    docRef
        .get()
        .then((doc) => {
            if (doc.exists) {
                console.log("exist: ", doc);
                var docData = doc.data();

                if (checked) {
                    console.log(checked);
                    docData.favorites.push(id);
                    docRef
                        .update(docData)
                        .then(() => {
                        })
                        .catch((error) => {
                            console.error("Error update document: ", error);
                        });
                } else {
                    console.log(checked);
                    var result = [];
                    docData.favorites.map((elt) => {
                        if (elt !== id) {
                            result.push(elt);
                        }
                        return elt;
                    });
                    docData.favorites = result;
                    docRef
                        .update(docData)
                        .then(() => {
                        })
                        .catch((error) => {
                            console.error("Error remove document: ", error);
                        });
                }
            } else {
                console.log("exist: ", doc.exists);
                console.log("No such document!");
                usersCOllection
                    .doc(user.uid)
                    .set({
                        userID: user.uid,
                        favorites: [id],
                        email: user.providerData[0].email,
                    })
                    .then(() => {
                        console.log("Document successfully written!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
    return result;
};

const create = (data) => {
    return db.add(data);
};

const changePassword = (ancien, nouveau) => {
    let result = false;
    // Formater l'objet recupérer depusi la section
    let user = JSON.parse(window.localStorage.getItem(LoginConsts.USERNAME));
    console.log("getUserById - User-Service");

    // Récupérer le user par son ID depuis Firebase
    var docRef = usersCOllection.doc(user.uid);

    // Traitement de la récupération
    usersCOllection
        .doc(user.uid)
        .get()
        .then((doc) => {
                let docData = doc.data();
                console.log("Doc Data", docData);
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });

    return result;
};

const update = (id, value) => {
    return db.doc(id).update(value);
};

const remove = (id) => {
    return db.doc(id).delete();
};

const firestoreService = {
    getAll,
    getUserById,
    getArtistByFavoriteId,
    getArtistByDocId,
    getFavoritesByUserId,
    getRequestsByUserId,
    getUserByIdUnique,
    create,
    update,
    remove,
    changePassword
};

export default firestoreService;
