import React, { Component ,useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import db from "../../config/auth-firebase";
import ModeConnected from "./ModeConnected";
import ModeDisconnected from "./ModeDisconnected";
import { AuthenticationService } from "./../Accounts/authentication/authentication-service";
import SearchDashboard from "./../SearchDashboard/SearchDashboard";

const artistsRef = db.collection("artists");

const Headerc = (props) => {
  // render() {
    /* const [dataSearching, setDataSearching] = useState({
      loading: false,
      repos: [],
    }); */
  
    // useEffect(() => {
      // console.log("props.data => Component Header");
      // console.log(props.searchValue);
      // SearchByName(props.searchValue)
      // Will return all Springfields
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.searchValue]);

    /* const searchQuerying = (event) => {
      props.handleSearch(event);
    } */
    return (
      <header className="navbar navbar-expand-lg navbar-dark">
        {/*{AuthenticationService.isAuthenticated() ? (
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>) : ("")
        }*/}
        <div className="container position-relative">
          <Link to="/home">
            <img
              src={props.imageData}
              alt="brand-logo"
              className="goodHC"
            />
            <img
              className="navbar-brand-sticky"
              src="/img/logo.png"
              alt="sticky brand-logo"
            />
          </Link>
          <div className="navbar-inner">
            <nav>
              <div class="collapsex navbar-collapsxe" id="navbarSupportedContent">
              <ul className="navbar-nav" id="navbar-nav">
              { !AuthenticationService.isAuthenticated() ? (
                <li className="li_offline">
                  <br />
                  <div>
                    <i
                      className="fa fa-search fa-lg fa-fw pi_off"
                      aria-hidden="true"
                      color="white"
                    >{""}</i>
                    <input
                      id="loupe"
                      type="text"
                      name="loupe"
                      onChange={(event) => props.handleSearch(event)}
                      value={props.searchString}
                      placeholder=" Search"
                      className="transparent1"
                    />
                    <hr className="bar2d" />
                  </div>
                  {props.searchValue !== "" ? (
									<div className="cdk-overlay-pane">
										<div className="mat-autocomplete-panel ng-star-inserted mat-autocomplete-visible">
											{props.dataSearching.loading
												? props.dataSearching.repos.map(
														(elt) => {
															return (
																<div className="SearchItem">
																	<SearchDashboard
																		key={
																			elt.id
																		}
																		searchDashboard={
																			elt
																		}
																		{...props}
																	/>
																	<br />
																</div>
															);
														}
												  )
												: ""}
										</div>
									</div>
								) : (
									""
								)}
                </li>
                ) : (
                  <li>
                  <br />
                  <div className="my-search-bar">
                    <i
                      className="fa fa-search fa-lg fa-fw pi"
                      aria-hidden="true"
                      color="white"
                    >{""}</i>
                    <input
                      id="loupe"
                      type="text"
                      name="loupe"
                      onChange={(event) =>
                        props.handleSearch(event)
                      }
                      value={props.searchString}
                      placeholder=" Recherche"
                      className="transparent1"
                    />
                    <hr className="bar2" />
                  </div>
                  {props.searchValue !== "" ? (
									<div className="cdk-overlay-pane">
										<div className="mat-autocomplete-panel ng-star-inserted mat-autocomplete-visible">
											{props.dataSearching.loading
												? props.dataSearching.repos.map(
														(elt) => {
															return (
																<div className="SearchItem">
																	<SearchDashboard
																		key={
																			elt.id
																		}
																		searchDashboard={
																			elt
																		}
																		{...props}
																	/>
																	<br />
																</div>
															);
														}
												  )
												: ""}
										</div>
									</div>
								) : (
									""
								)}
                </li>
                )}
              
                { AuthenticationService.isAuthenticated() ? (
                  <ModeConnected />
                ) : (
                  <ModeDisconnected />
                )}
              </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  // }
}

export default Headerc;
