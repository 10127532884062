import db from "../config/auth-firebase";
import { ArtistConts } from "./ArtistConts";

const reviewsRef = db.collection("reviews");

async function getAll() {
	var result = [];
	//    console.log("getAll - Reviews");
	return reviewsRef.get().then((querySnapshot) => {
		querySnapshot.docs.map((doc) =>
			result.push({ id: doc.id, value: doc.data() })
		);
		//            console.log(result);
		return result;
	});
}

async function getreviewsById(id, category) {
	let resultLocal = null;
	//    console.log("resultLocal data:", id);
	//    console.log("resultLocal data:", resultLocal);
	try {
		let result = null;
		if (resultLocal) {
			resultLocal.map((elt) => {
				if (elt.id === id) {
					result = elt;
				}
			});
		}

		if (result) {
			return result;
		} else {
			return await reviewsRef
				.doc(id)
				.get()
				.then(function (doc) {
					if (doc.exists) {
						// console.log("Document data:", doc.data());
						return doc.data();
					} else {
						// doc.data() will be undefined in this case
						console.log("No such document!");
						return null;
					}
				})
				.catch(function (error) {
					console.log("Error getting document:", error);
				});
		}
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getreviewsBySearchValue(searchValue) {
	try {
		return await reviewsRef
			.orderBy("lastname")
			.orderBy("priority", "desc")
			.startAt(searchValue)
			.get()
			.then((querySnapshot) => {
				var result = [];
				querySnapshot.docs.map((doc) =>
					result.push({ id: doc.id, value: doc.data() })
				);
				// console.log(result);
				return result;
			});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getReviewsByArtist(artist) {
	try {
		return await reviewsRef
			.where("artistID", "==", artist)
			.get()
			.then((querySnapshot) => {
				var result = [];
				querySnapshot.docs.map((doc) =>
					result.push({ id: doc.id, value: doc.data() })
				);
				console.log(result);
				return result;
			});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

const reviewsService = {
	getAll,
	getreviewsById,
	getreviewsBySearchValue,
	getReviewsByArtist,
};

export default reviewsService;
