const eventsData = [    
    { key: '0',  value:"Amusement" },
    { key: '1',  value:"Anniversaire" },
    { key: '2',  value:"Diplôme" },
    { key: '3',  value:"Excuses" },
    { key: '4',  value:"Félicitations" },
    { key: '5',  value:"Maman" },
    { key: '6',  value:"Mariage" },
    { key: '7',  value:"Noël" },
    { key: '8',  value:"Rétablissement" }
]

export { eventsData };