/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { eventsData } from "../../config/events";

const RequestOnly = (props) => {
	const [initData, setInitData] = useState([]);
	const [events, setEvents] = useState([]);

	useEffect(() => {
		setInitData(props.initDataLoad);
		setEvents(props.events);
	}, [props.initDataLoad]);

	return (
		<>
			<div className="form-group text-left mt-2">
				<label className="font-weight-bold"> {initData.title_select}</label>
				<div className="formDesign">
					<select
						name="evenement"
						className="seltex"
						onChange={(event) =>
							props.handleEvenement(event.target.value)
						}
					>
						<option value={initData.subtitle_select}>
							{initData.subtitle_select}
						</option>
						{events.map((elt) => {
							return (
								<option key={elt.key} value={elt.value}>
									{elt.value}
								</option>
							);
						})}
					</select>
				</div>
			</div>

			<div className="form-group text-left">
				<label className="font-weight-bold"> {initData.title_instruction}</label>
				<div className="input-group formDesign">
					<textarea
						className="form-control"
						onChange={(event) =>
							props.handleInstruction(event.target.value)
						}
						id="instruction"
						name="instruction"
						placeholder={initData.subtitle_instruction}
						required="required"
						rows="3"
					></textarea>
				</div>
			</div>

			<div className="form-group text-left">
				<label className="font-weight-bold"> {initData.title_numero}</label>
				<div className="input-group formDesign">
					<div className="input-group-prepend">
						<span className="input-group-text">
							<i className="fas fa-phone" />
						</span>
					</div>
					<input
						type="text"
						className="form-control"
						onChange={(event) =>
							props.handlePhone(event.target.value)
						}
						name="phone"
						maxLength="11"
						placeholder={initData.subtitle_numero}
						required="required"
					/>
				</div>
			</div>
			{initData.forEntreprise ? (
				""
			) : (
				<div className="form-group text-left">
					<div className="form-check ">
						<input
							type="checkbox"
							className="form-check-input"
							name="isPublic"
							onChange={(event) =>
								props.handleIsPublic(event.target.checked)
							}
							id="exampleCheck1"
						/>
						<label className="font-weight-bold form-check-label"
							htmlFor="exampleCheck1"
						>
							Rendre votre video publique
						</label>
					</div>
				</div>
			)}
		</>
	);
};

export default RequestOnly;
