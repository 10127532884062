import React, {useEffect, useState} from "react";
import "./ScreenDetails.css";
import {Link} from "react-router-dom";
import reviewsService from "./../../services/reviews-service";
import Reviews from "./../Reviews/Reviews";
import {AuthenticationService} from "../Accounts/authentication/authentication-service";
import {Chip, Grid} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';

const initData = {
    image: "/img/h3.png",
    heading: "Larry Hankin",
    content: "  CEO of Themeland ",
    title1: "Comedian",
    title2: "influenceur",
    comment: "Engineer by day, pimp by night",
    clock: "Répond généralement dans un délai de 6 jours",
    btn: "Book now",
    headingSlug: "",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
    heroThumb: "/img/h3.png",
};

const Detail = (props) => {
    const [data, setData] = useState({loading: false, repos: {}});
    const [dataDetails, setDataDetails] = useState({
        loading: false,
        repos: null,
        id: null,
    });
    const [reviews, setReviews] = useState({loading: false, repos: null});

    console.log('Artist', props.artist);

    useEffect(() => {
        setData({loading: true, repos: initData});

        if (props.artist)
            setDataDetails({
                loading: true,
                repos: props.artist,
                id: props.id,
            });

        HandleReviewsList();
    }, [props.artist]);

    async function HandleReviewsList() {
        try {
            // let result = await reviewsService.getAll();
            let result = await reviewsService.getReviewsByArtist(props.id);
            console.log("Reviews result", result);
            if (result) {
                let reviews_list = [];
                result.map((elt) => {
                    // if (elt.id === props.artist.id) {
                    console.log("HandleReviewsList data:", elt);
                    reviews_list.push(elt);
                    // } else {
                    console.log("Reviews result X", result);
                    // }
                });
                setReviews({loading: true, repos: reviews_list});
            }
        } catch (rejectedValue) {
            // …
        }
    }

    let history = useHistory();

    return (
        <div className="detailsPage">
            <div className="detailsPage1">
                <div className="detailsPage2_left">
                    <div className="row-arrow">
                        <Link to="/" className="par">
                            <h5 className="ButtonLeftMobile">
                                {" "}
                                <i
                                    className="fa fa-arrow-left"
                                    aria-hidden="true"
                                >{""}</i>
                                <span className="value">Retour</span>
                            </h5>
                        </Link>
                    </div>
                    {dataDetails.loading ? (
                        <div className="px-0">
                            {/* Welcome Intro Start */}
                            <div
                                id="videoDetailDiv1"
                                className="videoDetailDiv1 col-sm-12 col-lg-1"
                            >
                                {/* Welcome Thumb */}
                                <div className="videoDetailDiv1_1">
                                    {/*<img src={data.repos.heroThumb} alt="" width="250" height="100" />*/}
                                    <video
                                        id="videoDetail"
                                        className="videoDetail"
                                        src={dataDetails.repos.urlVideo}
                                        controls
                                    >
                                        {" "}
                                    </video>
                                </div>
                            </div>

                            <div
                                id="un"
                                className="videoDetailDiv2 col-12 col-sm-12  col-lg-15"
                            >
                                <br/>
                                <br/>
                                <br/>
                                <div className="videoDetailDiv2_1">
                                    <div className="ImageNameCountry">
                                        <div className="ImageNameCountry1">
                                            <img
                                                src={
                                                    dataDetails.repos.urlPicture
                                                }
                                                alt=""
                                                className="ImageArtist"
                                            />
                                        </div>
                                        <div id="ImageNameCountry12">
                                            <h1 className="text-secondary1 nameArtistDetail">
                                                {dataDetails.repos.name}
                                            </h1>
                                            {/*<p className="ext-secondary3 countryArtistDetail">
                                                {dataDetails.repos.country}
                                            </p>*/}
                                            {/*<div className="rowButton">
                                                <Chip
                                                    className="mr-1 chips"
                                                    size="small"
                                                    label={
                                                        dataDetails.repos.title
                                                    }
                                                    color="primary"
                                                />
                                                <Chip
                                                    className="mr-1 chips"
                                                    size="small"
                                                    label={
                                                        dataDetails.repos
                                                            .category
                                                    }
                                                    color="primary"
                                                />
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div id="videoDetailDiv2_2">
                                        <div className="rowButton artist-detail-category mt-2">
                                            {/*<Chip
                                                avatar={
                                                    <Avatar>{dataDetails.repos.title[0]}</Avatar>
                                                }
                                                className="mr-1 chips"
                                                size="small"
                                                label={
                                                    dataDetails.repos.title
                                                }
                                                color="primary"
                                            />
                                            <Chip
                                                avatar={
                                                    <Avatar>{dataDetails.repos.category[0]}</Avatar>
                                                }
                                                className="mr-1 chips"
                                                size="small"
                                                label={
                                                    dataDetails.repos
                                                        .category
                                                }
                                                color="primary"
                                            />*/}
                                            { `${dataDetails.repos.category} - ${dataDetails.repos.title}` }
                                        </div>
                                        <p className="my-2">{data.repos.clock}</p>
                                        <p className="descriptionArtist">
                                            {dataDetails.repos.description}{" "}
                                        </p>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <div className="px-0">
                                            <div className="d-flex flex-column requestBox">
                                                <div className="row px-0 mb-5">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 px-1">
                                                        <h4>Demande de vidéo</h4>
                                                        <p className="mt-3">
                                                            Achetez un message vidéo de votre personnalité favorite. Parfait pour toute occasion !
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 payBtnGroup">
                                                        <Link
                                                            to={
                                                                AuthenticationService.isAuthenticated()
                                                                    ? `/requestbooknow/${dataDetails.id}`
                                                                    : `/accountbooknow/${dataDetails.id}`
                                                            }
                                                            className="text-secondary4"
                                                        >
                                                            <button className="btn btn-secondary btn-block btn-lg mt-4 payBtn">
                                                                Demander une video{" "} {" (" +
                                                            dataDetails.repos
                                                                .price +
                                                            "€)    "}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="row px-0">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 px-1">
                                                        <h4>Pour les entreprises</h4>
                                                        <p className="mt-3">
                                                            Vous cherchez une célébrité pour un évènement privé, votre marque cherche son futur ambassadeur, Only2U vous donne accès aux célébrités  en un seul clic.
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 payBtnGroup">
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    AuthenticationService.isAuthenticated()
                                                                        ? `/requestbooknow/${dataDetails.id}`
                                                                        : `/accountbooknow/${dataDetails.id}`,
                                                                state: {
                                                                    forEntreprise: true,
                                                                },
                                                            }}
                                                            className="text-secondary4"
                                                        >
                                                            <button type="button" className="btn btn-block btn-secondary btn-lg mt-4 payBtn">
                                                                Envoyer une demande
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {/* <div style={{display: "inline", float: "rigth"}}>{"Span def"}</div> */}
                </div>

                <div className="detailsPage2_rigth">
                    {reviews.loading ? (
                        <Reviews
                            reviews={reviews.repos}
                            artist_id={props.artist.id}
                            {...props}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
};

export default Detail;
