import React, { Component } from 'react';





class Header extends Component {



    render() {

        return (
            <header className="navbar navbar-expand-lg navbar-dark mb-5">
                <div className="container position-relative mb-5">
                    <a href="/">
                        <img src={this.props.imageData} alt="brand-logo" className="goodLG mb-5" />
                    </a>
                </div >
            </header >
        );
    }
}

export default Header;