import React, { useEffect } from "react";

// importing MyRouts where we located all of our theme  anonSignIn
import MyRouts from "./routers/routes";
import { anonSignIn } from "./config/auth-firebase";

import { positions, Provider } from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";

// import "bootstrap/dist/bootstrap.min.css";

const options = {
	position: positions.BOTTOM_RIGHT,
};

const App = () => {
	useEffect(() => {
		anonSignIn();
	});

	return (
		<Provider template={AlertMUITemplate} {...options}>
			<MyRouts />
		</Provider>
	);
};

export default App;
