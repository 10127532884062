import firebase from "firebase";
// These samples are intended for Web so this import would normally be
// done in HTML however using modules here is more convenient for
// ensuring sample correctness offline.
import "firebase/firestore";
import { LoginConsts } from './../components/Accounts/authentication/login-consts';
import { AuthenticationService } from './../components/Accounts/authentication/authentication-service';

require("firebase/auth");

firebase.initializeApp({
  apiKey: "AIzaSyCmsUysvZIGXV9mJPQsHfCmyJD6Jgf9K_g",
  authDomain: "onlyu-26da0.firebaseapp.com",
  databaseURL: "https://onlyu-26da0.firebaseio.com",
  projectId: "onlyu-26da0",
  storageBucket: "onlyu-26da0.appspot.com",
  messagingSenderId: "996372058078",
  appId: "1:996372058078:web:7d81b7d8644e870fa3d74f",
  measurementId: "G-2FHNY7DED8",
});

export function anonSignIn() {
  // [START auth_anon_sign_in]
  firebase
    .auth()
    .signInAnonymously()
    .then((response) => {
      // Signed in..
      // console.log("Signed in..");
      // console.log(response);
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
      console.log("Signed in.. - Error");
      console.log(errorCode);
      console.log(errorMessage);
    });
  // [END auth_anon_sign_in]
}

export function authWithGoogle() {
  // [START auth_anon_sign_in]
  var provider = new firebase.auth.GoogleAuthProvider();

  /**
   * Facultatif : spécifiez les étendues OAuth 2.0 supplémentaires que vous souhaitez
   * demander au fournisseur d'authentification. Pour ajouter une portée, appelez addScope .
   * Par example:
   */
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
      console.log("authWithGoogle Method")
      console.log("credential: ", credential)
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log("token: ", token)
      console.log("user: ", user)
      window.localStorage.setItem(LoginConsts.ACCESS_TOKEN_KEY, token);
      window.localStorage.setItem(LoginConsts.USERNAME, user);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // The email of the user's account used.
      // var email = error.email;
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // ...
    });
  // [END auth_anon_sign_in]
}

export function authWithFacebook() {
  // [START auth_anon_sign_in]
  var provider = new firebase.auth.FacebookAuthProvider();

  /**
   * Facultatif : spécifiez les étendues OAuth 2.0 supplémentaires que vous souhaitez
   * demander au fournisseur d'authentification. Pour ajouter une portée, appelez addScope .
   * Par example:
   */
  provider.addScope("user_birthday");

  firebase.auth().signInWithPopup(provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;

      // The signed-in user info.
      var user = result.user;

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var accessToken = credential.accessToken;

      window.localStorage.setItem(LoginConsts.ACCESS_TOKEN_KEY, accessToken);
      window.localStorage.setItem(LoginConsts.USERNAME, user);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // The email of the user's account used.
      // var email = error.email;
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;

      // ...
    });
  // [END auth_anon_sign_in]
}

export function authLogout() {
  firebase.auth().signOut().then(() => {
    // Sign-out successful.
    AuthenticationService.logOff();
  }).catch((error) => {
    // An error happened.
  });
  // [END auth_anon_sign_in]
}

const db = firebase.firestore();

export const auth = firebase.auth();
export default db;
