import React, { useState, useEffect } from "react";
import db from "../../config/auth-firebase";
import SearchDashboard from "./../SearchDashboard/SearchDashboard";
import { Link } from "react-router-dom";
import ModeConnected from "./ModeConnected";
import ModeDisconnected from "./ModeDisconnected";
import { AuthenticationService } from "./../Accounts/authentication/authentication-service";

const artistsRef = db.collection("artists");
const Header = (props) => {
	const [dataSearching, setDataSearching] = useState({
		loading: false,
		repos: [],
	});

	useEffect(() => {
		// console.log("props.data => Component Header");
		// console.log(props.searchValue);
		// SearchByName(props.searchValue)
		// Will return all Springfields
		if (props.searchValue !== "") {
			artistsRef
				.orderBy("lastname")
				.orderBy("priority", "desc")
				.startAt(props.searchValue)
				.get()
				.then((querySnapshot) => {
					var result = [];
					querySnapshot.docs.map((doc) =>
						result.push({ id: doc.id, value: doc.data() })
					);
					console.log(result);
					setDataSearching({
						loading: true,
						repos: result,
					});
				});
		} else {
			setDataSearching({
				loading: false,
				repos: [],
			});
		}
	}, [props.searchValue]);

	return (
		<header className="navbar navbar-expand-lg navbar-dark">
			<div className="container position-relative">
				<Link to="/home" id="WelcomeHome">
					<img
						src={props.imageData}
						alt="brand-logo"
						className="good"
					/>
					<img
						className="navbar-brand-sticky"
						src="/img/logo.png"
						alt="sticky brand-logo"
					/>
				</Link>
				<div className="navbar-inner">
					<nav>
						<ul className="navbar-nav" id="navbar-nav">
							<li>
								<br />
								<div className="in searchbar mx-auto">
									<i
										className="fa fa-search fa-lg fa-fw pic"
										aria-hidden="true"
										color="white"
									></i>
									<input
										id="loupe"
										type="text"
										name="loupe"
										onChange={(event) =>
											props.handleSearch(event)
										}
										value={props.searchString}
										placeholder=" Search"
										className="transparent"
										style={{
											marginLeft: "3%",
											color: "white",
										}}
									/>
									<hr className="bar" />
								</div>
								{props.searchValue !== "" ? (
									<div className="cdk-overlay-pane">
										<div className="mat-autocomplete-panel ng-star-inserted mat-autocomplete-visible">
											{dataSearching.loading
												? dataSearching.repos.map(
														(elt) => {
															return (
																<div className="SearchItem">
																	<SearchDashboard
																		key={
																			elt.id
																		}
																		searchDashboard={
																			elt
																		}
																		{...props}
																	/>
																	<br />
																</div>
															);
														}
												  )
												: ""}
										</div>
									</div>
								) : (
									""
								)}
							</li>

							{AuthenticationService.isAuthenticated() ? (
								<ModeConnected />
							) : (
								<ModeDisconnected />
							)}
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
