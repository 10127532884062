import React, { useState, useEffect } from "react";
import Headerd from "../HeaderSection/Headerd";
import Footer from "../FooterSection/Footer";
import {
  auth,
  authWithGoogle,
  authWithFacebook,
} from "./../../config/auth-firebase";
import { useAlert } from "react-alert";
import { Link, useParams } from 'react-router-dom';
import userService from '../../services/users-service';
import { AuthenticationService } from '../Accounts/authentication/authentication-service';

const initDataLoad = {
  heading: "Créer un compte!",
  content: "Quand offrir les mêmes vieux cadeaux, jouets ou fleurs devient trop ennuyeux, vous pouvez offrir à vos proches une surprise qu'ils n'oublieront jamais.  Que ce soit pour une occasion spéciale, ou pas du tout, Oulo est le cadeau le plus mémorable pour vos proches… ou pour vous-même!  Chanteurs, influenceurs, acteurs, footballeurs, youtubeurs et bien d'autres sont là pour réaliser votre souhait",
  formHeading: "Se connecter",
  formContent: "Remplissez tous les champs afin que nous puissions obtenir des informations sur vous. Nous ne vous enverrons jamais de spam",
  formText: "Vous n'avez pas de compte?",
  btnText: "Se connecter",
  btnText_2: "S'enregistrer",
};

const LogAccountBookNow = props => {
  const [initData, setInitData] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [search, setSearch] = useState("");
  const alert = useAlert();
  let { id } = useParams();
  // const [name, setName] = useState("");
  // const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event) => {
    event.preventDefault();
    console.log("login building");

    auth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        console.log(response.user);
        AuthenticationService.authenticate(response.user);
        var promise = userService.getFavoritesByUserId();
        promise.then(dataResult => {
          // console.log(dataResult);
          console.log("result", dataResult);
          AuthenticationService.setFavouritesOfUserIds(dataResult);
          props.history.push(`/requestbooknow/${id}`)
        });
      })
      .catch((err) => {
        console.log("login error");
        console.log(err);
        alert.show(err.message, {
          title: err.code,
        });
      });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
    // else if (name === "displayName") {
    //     setName(value);
    // }
  };

  useEffect(() => {
    setInitData(initDataLoad);
  }, []);

  const handleSearch = (event) => setSearch(event.target.value);

    return (
      <div className="container">
        <div className="d-flex flex-column align-items-center justify-content-center">
        <Headerd
          searchValue={search}
          handleSearch={handleSearch}
          imageData={"/img/logo-white.png"}
        />
          <section
            className="h-100vh d-flex align-items-center justify-content-center"
          >
            <div className="container mt-lg-5 d-flex align-items-center justify-content-center">
              <div className=" pt-lg-5 d-flex align-items-center justify-content-center">
                <div className="px-lg-5 mx-lg-5 mx-sm-5 px-xs-5">
                  {/* Contact Box */}
                  <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                    {/* Contact Form */}
                    
                      <div className="contact-top">
                        <h3 className="contact-title">{initData.formHeading}</h3>
                        <h5 className="text-secondary fw-3 py-3">
                          {initData.formContent}
                        </h5>
                      </div>
                      
                      <div>
                        <span style={{ transition: "opacity 0.5s ease 0s" }}>
                          <button 
                            type="button" 
                            class="btn btn-block btn-primary btn-sm btn-fb metro loginBtn--facebook" 
                            style={{ fontFamily: "cerebri, sans-serif", fontWeight: "500", textTransform: "none" }}                          
                            onClick={(event) => authWithFacebook()}
                          >
                            <span>Continue with Facebook</span>
                          </button>
                        </span>
                        <div class="_18ajrTNfG7BRg-y2PDYD9X"><span class="_944rHfQa8HkvySxJQYhxo _3VGn4MnZlzhe_5zrohd1VS">Google</span></div>
                        
                        <span style={{ transition: "opacity 0.5s ease 0s" }}>
                          <button 
                            type="button" 
                            class="btn btn-block btn-primary btn-sm btn-fb metro loginBtn--google" 
                            style={{ fontFamily: "cerebri, sans-serif", fontWeight: "500", textTransform: "none" }}                          
                            onClick={(event) => authWithGoogle()}
                          >
                            <span>Continue with Google</span>
                          </button>
                        </span>
                      </div>
                      <div class="_18ajrTNfG7BRg-y2PDYD9X"><span class="_944rHfQa8HkvySxJQYhxo _3VGn4MnZlzhe_5zrohd1VS">OU</span></div>
                    <form id="contact-form">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-envelope-open" />
                                </span>
                              </div>
                              <input
                                  type="email"
                                  className="form-control"
                                  name="userEmail"
                                  value={email}
                                  placeholder="E.g: email@xxx.com"
                                  id="userEmail"
                                  onChange={event => onChangeHandler(event)}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-unlock-alt" />
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control"
                                name="userPassword"
                                value={password}
                                placeholder="Mot de passe"
                                id="userPassword"
                                onChange={event => onChangeHandler(event)}
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-bordered w-100 mt-3 mt-sm-4"
                            onClick={event => {
                            signInWithEmailAndPasswordHandler(event)}}
                          >
                            {initData.btnText}
                          </button>
                        </div>
                        <div className="col-12">
                          <span className="d-block pt-2 mt-4 border-top">
                            {initData.formText}{" "}
                            <Link to={`/accountbooknow/${id}`}>{initData.btnText_2}</Link>
                          </span>
                        </div>
                      </div>
                    </form>                    
                  </div>
                </div>
              </div>
            </div>
            <div className="bookNowContainerOffline">
              <div className="bookNowContent">
                <span className="hnoSxe">1. Compte</span>
                <span className="cxXlfs">2. Demande</span>
                <h1 className="lcahDC text-blac">Créer un compte</h1>
                <p className="IwhPr text-black">Nous vous informerons lorsque votre vidéo Only2u sera prête.</p>
                <div className="Styled__ExtraContextItem-hcrk8m-3 ckTGgS">
                  <i
                    className="fa fa-bullseye fa-lg fa-fw ckTgHS"
                    aria-hidden="true"
                    color="danger"
                  ></i>{" "}
                  <p className="ckTgIS text-black">Créez un compte pour pouvoir répondre à toute question</p>
                </div>
                <div className="Styled__ExtraContextItem-hcrk8m-3 ckTGgS">
                  <i
                    className="fa fa-star fa-lg fa-fw ckTgHS"
                    aria-hidden="true"
                    color="black"
                  ></i>{" "}
                  <p className="ckTgIS text-black">Restez connecté avec vos célébrités préférées</p>
                </div>
                <div className="Styled__ExtraContextItem-hcrk8m-3 ckTGgS">
                  <i
                    className="fa fa-deaf fa-lg fa-fw ckTgHS"
                    aria-hidden="true"
                    color="black"
                  ></i>{" "}
                  <p className="ckTgIS text-black">
Recevez des mises à jour sur les offres exclusives, les promotions et les nouvelles célébrités qui rejoignent Only2u</p>
                </div>
              </div>
            </div>
            {/* Shape Bottom */}
            {/* <div className="shape-bottom">
              <svg
                viewBox="0 0 1920 310"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className="svg replaced-svg"
              >
                <title>sApp Shape</title>
                <desc>Created with Sketch</desc>
                <defs />
                <g
                  id="sApp-Landing-Page"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="sApp-v1.0"
                    transform="translate(0.000000, -554.000000)"
                    fill="#FFFFFF"
                  >
                    <path
                      d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                      id="sApp-v1.0"
                    />
                  </g>
                </g>
              </svg>
            </div> */}
          </section>
          {/* <Footer /> */}
        </div>
      </div>
    );
};

export default LogAccountBookNow;
