
import db from '../../config/auth-firebase';
import {
  RETRIEVE_ARTISTS,
} from "./types";

// export const createArtist = (title, description) => async (dispatch) => {
//   try {
//     const res = await ArtistDataService.create({ title, description });

//     dispatch({
//       type: CREATE_Artist,
//       payload: res.data,
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
const artistsRef = db.collection('artists');

export const retrieveArtists = (category) => async (dispatch) => {
  try {
    var result = [];
    artistsRef.where('category', '==', category)
    .get()
    .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
            result.push({ id: doc.id, value: doc.data() })
            return doc.data();
        });
        // console.log(result);
        dispatch({
            type: RETRIEVE_ARTISTS,
            payload: result,
        });
    });

  } catch (err) {
    console.log(err);
  }
};

export const retrieveFavArtists = () => async (dispatch) => {
  try {
    var result = [];
    artistsRef
    .get()
    .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
            result.push({ id: doc.id, value: doc.data() })
            return doc.data();
        });
        // console.log(result);
        dispatch({
            type: RETRIEVE_ARTISTS,
            payload: result,
        });
    });

  } catch (err) {
    console.log(err);
  }
};

// export const updateArtist = (id, data) => async (dispatch) => {
//   try {
//     const res = await ArtistDataService.update(id, data);

//     dispatch({
//       type: UPDATE_Artist,
//       payload: data,
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

// export const deleteArtist = (id) => async (dispatch) => {
//   try {
//     await ArtistDataService.remove(id);

//     dispatch({
//       type: DELETE_Artist,
//       payload: { id },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const deleteAllArtists = () => async (dispatch) => {
//   try {
//     const res = await ArtistDataService.removeAll();

//     dispatch({
//       type: DELETE_ALL_ArtistS,
//       payload: res.data,
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

// export const findArtistsByTitle = (title) => async (dispatch) => {
//   try {
//     const res = await ArtistDataService.findByTitle(title);

//     dispatch({
//       type: RETRIEVE_ArtistS,
//       payload: res.data,
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };