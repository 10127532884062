import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { AuthenticationService } from "../Accounts/authentication/authentication-service";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";

const Card = (props) => {
	const [favorite, setFavorite] = useState(props.artist.favorite);

	props.artist.value.category = props.artist.value.category.replace('/', '-');

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		// console.log("Sport");
		// console.log(props.artist);
		// console.log(favorite);
		// setFavorite();
	}, [props]);

	const handleCheckboxChange = (event) => {
		console.log("Component Sport - handleCheckboxChange");
		console.log(event.target.checked);
		setFavorite(event.target.checked);
		console.log(favorite);
		props.handleChangedFavorite(event, props.artist.id);
		// window.location.reload();
	};

	return (
		<div id={props.artist.id} key={props.artist.id} className="hentry">
			<div className="talent-element-1">
				<div className="talent-element-2">
					<Link
						className="talent-container"
						to={`/details/${props.artist.id}/${props.artist.value.category}`}
					>
						<div className="priceFav">
							<div className="talent-price-container">
								EUR {props.artist.value.price}
							</div>
						</div>

						<div
							className="talent-image-container"
							style={{
								backgroundImage: `url(${props.artist.value.urlPicture})`,
							}}
						>
						</div>

						<div className="talent-content-container">
							<div className="talent-info-container">
								<div className="talent-infos">
									<div className="talent-nom">
										{props.artist.value.lastname}
									</div>
									<div className="talent-title">
										{props.artist.value.title}
									</div>

									<div className="talent-star">
										<i className="fas fa-star text-warning" />
										<i className="fas fa-star text-warning" />
										<i className="fas fa-star text-warning" />
										<i className="fas fa-star text-warning" />
										<i className="fas fa-star text-warning" />
									</div>
								</div>
							</div>
						</div>
					</Link>
					{AuthenticationService.isAuthenticated() ? (
						<span id="FavChecked">
							<Checkbox
								checked={favorite}
								onChange={handleCheckboxChange}
								icon={
									<span className="IconFav">
										<BookmarkBorderOutlinedIcon />
									</span>
								}
								checkedIcon={<BookmarkOutlinedIcon />}
								name="Favorite"
							/>
						</span>
					) : (
						""
					)}

					{/* <img src={"/img/pic.png"} alt="" className="pic1" />
                    
                   

                    <div className="post">
                        <h6 className="text-secondary3 fw-6">{props.artist.value.title}</h6>
                    </div>
                    
                    <div className="star">
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                    </div> */}
				</div>
			</div>
		</div>
	);
};

export default Card;
