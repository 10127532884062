import React from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";

const Player = (props) => {
	// render() {
	const { open, toggleModal, urlVideo } = props;
	console.log("URL", urlVideo);
	console.log("toggle", toggleModal);
	return (
		<Modal
			open={open}
			onClose={toggleModal}
			styles={{
				modal: {
					maxWidth: "unset",
					width: "100%",
					padding: "unset",
				},
				overlay: {
					background: "rgba(0, 0, 0, 0.5)",
				},
				closeButton: {
					background: "#7c4fe0",
				},
			}}
			center
		>
			<div className="player-wrapper">
				<ReactPlayer
					className="react-player"
					playing
					url={urlVideo}
					width="100%"
					height="calc(100vh - 100px)"
				/>
			</div>
		</Modal>
	);
	// }
};

export default Player;
