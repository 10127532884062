import React from "react";

const PaymentBookNow = () => {
	return (
		<div>
			<h1>Valider</h1>
		</div>
	);
};

export default PaymentBookNow;
