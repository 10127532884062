import React, { Component } from 'react';
import Header from '../HeaderSection1/Header';
import Footer from '../FooterSection/Footer';

const initData = {

    formHeading: "Formulaire",
    formContent: "Request a video",
    formText: "Don't have an account?",
    btnText: "Envoyer la demande",
    btnText_2: "S'enregistrer"
}

class form extends Component {
    state = {
        initData: []
    }
    componentDidMount() {
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <div className="homepage-5 accounts inner-pages">
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <section id="home" className="section welcome-area h-100vh bg-overlay d-flex align-items-center">
                        <div className="container">
                            <div className="row">
                                <a href="/details" >
                                    <i class="fa fa-arrow-left fa-lg fa-fw " aria-hidden="true" style={{ color: "white" }} ></i>  <h5 className="para">Back</h5>
                                </a>
                            </div>
                            <div className="row align-items-center justify-content-center">

                                {/* Welcome Intro Start */}

                                <div className="col-12 col-md-8 col-lg-5 ">
                                    {/* Contact Box */}
                                    <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg move ">
                                        {/* Contact Form */}
                                        <form id="contact-form"  >
                                            <div className="contact-top">
                                                <h3 className="contact-title">{this.state.initData.formHeading}</h3>
                                                <h5 className="text-secondary fw-3 py-3">{this.state.initData.formContent}</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    {/*<div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fas fa-envelope-open" /></span>
                                                            </div>
                                                            <input type="email" className="form-control" name="email" placeholder="To" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fas fa-envelope-open" /></span>
                                                            </div>
                                                            <input type="email" className="form-control" name="email" placeholder="From" required="required" />
                                                        </div>
        </div>*/}

                                                    <div className="form-group">
                                                        <label> What's the occasion?</label>
                                                        <div className="input-group align-center centr">
                                                            <select name="example" className="seltex" >
                                                                <option value="A">Birthday</option>
                                                                <option value="B">Wedding</option>
                                                                <option value="-">Anniversary</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">

                                                            <input type="text" className="form-control" name="email" placeholder="What would you like him/her to say?" required="required" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fas fa-phone" /></span>
                                                            </div>
                                                            <input type="text" className="form-control" name="number" maxLength="11" placeholder="33 xx xx xx xxx" required="required" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-bordered w-100 mt-3 mt-sm-4" type="submit">{this.state.initData.btnText}</button>

                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Shape Bottom */}
                        <div className="shape-bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                <path className="shape-fill" fill="#FFFFFF" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                            </svg>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default form;