import React, { useState, useEffect } from "react";
import FooterSection from "../FooterSection/Footer";
import Headerd from "../HeaderSection/Headerd";
import ScreenPeoples from "./screen-peoples";
import { ArtistConts } from "../../services/ArtistConts";
import artistsService from "../../services/artists-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "../ScreenCard/Card";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { AuthenticationService } from "../Accounts/authentication/authentication-service";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";
import Checkbox from "@material-ui/core/Checkbox";

const Peoples = (props) => {
	const { category } = useParams()
	const promise = artistsService.getArtistsByCategory(category.split('-').join('/').toLowerCase());
	const [data, setData] = useState({ loading: false, repos: null });
	const [search, setSearch] = useState("");
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (data.repos === null || data.repos === undefined) {
			promise.then((dataResult) => {
				console.log(dataResult);
				setData({ loading: true, repos: dataResult });
			});
		}
		progressSpinner();
	}, [data.repos]);

	const progressSpinner = () => {
		const timer = setInterval(() => {
			setProgress((prevProgress) =>
				prevProgress >= 100 ? 0 : prevProgress + 10
			);
		}, 800);

		return () => {
			clearInterval(timer);
		};
	};

	const handleSearch = (event) => setSearch(event.target.value);

	let history = useHistory();

	return (
		<>
			<div id="scrollUp" title="Scroll To Top">
				<i className="fas fa-arrow-up" />
			</div>
			<div className="mainFav" style={{ overflow: "hidden" }}>
				<Headerd
					searchValue={search}
					handleSearch={handleSearch}
					imageData={"/img/logo-white.png"}
				/>
				<div className="FavPage">
					<div className="mat-drawer-backdrop ng-star-inserted"></div>
					<div id="home" className="FavPageContainer">
						<div className="FavPageContent">
							<div className="PageContainer">
								<div className="FavContainer">
									<h2 className="text-center mb-3">{category.toUpperCase()}</h2>

									<div className="FavCard">
										<div className="FavCardContainer">
											<div className="FavCardContent">
												{data.loading ? (
													data.repos.map((elt) => {
														return (
															 <div
																id={elt.id}
																key={elt.id}
																className="hentry"
															>
																<div className="talent-element-1">
																	<div className="talent-element-2">
																		<Link
																			className="talent-container"
																			to={`/details/${elt.id}/${elt.value.category}`}
																		>
																			<div className="priceFav">
																				<div className="talent-price-container">
																					EUR{" "}
																					{
																						elt
																							.value
																							.price
																					}
																				</div>
																			</div>

																			<div
																				className="talent-image-container"
																				style={{
																					backgroundImage: `url(${elt.value.urlPicture})`,
																				}}
																			>
																				{/* <span className="float-right mr-2" >
																					<i className="fas fa-2x fa-bookmark text-light"></i>
																				</span> */}
																			</div>

																			<div className="talent-content-container">
																				<div className="talent-info-container">
																					<div className="talent-infos">
																						<div className="talent-nom">
																							{
																								elt
																									.value
																									.lastname
																							}
																						</div>
																						<div className="talent-title">
																							{
																								elt
																									.value
																									.title
																							}
																						</div>

																						<div className="talent-star">
																							<i className="fas fa-star text-warning" />
																							<i className="fas fa-star text-warning" />
																							<i className="fas fa-star text-warning" />
																							<i className="fas fa-star text-warning" />
																							<i className="fas fa-star text-warning" />
																						</div>
																					</div>
																				</div>
																			</div>
																		</Link>
																	</div>
																</div>
															</div>
															 
															 
														);
													})
												) : (
													<span id="progressSpinner">
														<CircularProgress
															variant="determinate"
															value={progress}
														/>
													</span>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterSection />
		</>
	);
};

export default Peoples;
