import React, { useEffect } from "react";
import './SearchDashboard.css';
import { Link } from 'react-router-dom';

const SearchDashboard = (props) => {

    useEffect(() => {
        // console.log("SearchDashboard - Component");
        // console.log(props.searchDashboard)
    })
    
  return (
    <div className="auto-complete mat-option ng-star-inserted" tabindex="0" id="mat-option-0" aria-disabled="false">
            <span className="mat-option-text">
                <Link redirect className="" to={`/details/${props.searchDashboard.id}/${props.searchDashboard.value.category}`} >
                    <div className="mat-option-text-fxlayout" fxlayout="row" fxlayoutalign="none center" fxlayoutgap="10px">
                        <img src={props.searchDashboard.value.urlPicture} alt="" className="impegSearchDashboard talent-image  ng-star-inserted ng-lazyloaded" fxflex="55px" /> 
                        <div className="fxflex" fxflex="grow" fxlayout="column">
                            <span className="option-name"> {props.searchDashboard.value.name} </span>
                            <div><span className="option-status"> {props.searchDashboard.value.category} </span></div>
                            <div fxlayout="row wrap" fxlayoutalign="none center" fxlayoutgap="5px" className="ng-star-inserted">
                                <div className="talent-container ng-star-inserted"> {props.searchDashboard.value.title} </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </span>
        </div>
  );
};

export default SearchDashboard;
