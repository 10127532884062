/* eslint-disable default-case */
import { AuthenticationService } from "../components/Accounts/authentication/authentication-service";
import db, { anonSignIn } from "./../config/auth-firebase";
import { ArtistConts } from "./ArtistConts";
import userService from "./users-service";

const artistsRef = db.collection("artists");
anonSignIn();
const getAll = () => {
	var result = [];
	artistsRef
		.where("category", "==", "acteurs/actrices")
		.get()
		.then((querySnapshot) => {
			querySnapshot.docs.forEach((doc) =>
				result.push({ id: doc.id, value: doc.data() })
			);
		});
	return result;
};

async function getFavouritesByUserId() {
	var favList = userService.getFavoritesByUserId();
	let resultGlobal = [];
	try {
		return new Promise((resolve) => {
			favList.then((dataResult) => {
				// console.log('favList: ', dataResult)
				dataResult.forEach((_elt) => {
					return new Promise((resolve) => {
						// Get data from FireStore database
						var resultLocal = artistsRef
							.doc(_elt)
							.get()
							.then((doc) => {
								return { id: _elt, value: doc.data() };
							})
							.catch((error) => console.log(error));
						setTimeout(() => {
							resolve(resultLocal);
							resultLocal.then((dataResult) => {
								// console.log(dataResult);
								resultGlobal.push(dataResult);
							});
						}, 2000);
					});
				});
			});

			setTimeout(() => {
				resolve(resultGlobal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getArtistsById(id) {
	let resultLocal = [];
	try {
		return new Promise((resolve) => {
			// Get data from FireStore database
			resultLocal = artistsRef
				.doc(id)
				.get()
				.then((doc) => {
					return doc.data();
				})
				.catch((error) => console.log(error));
			setTimeout(() => {
				resolve(resultLocal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getArtistsBySearchValue(searchValue) {
	try {
		return await artistsRef
			.orderBy("lastname")
			.orderBy("priority", "desc")
			.orderBy("priority", "asc")
			.startAt(searchValue)
			.get()
			.then((querySnapshot) => {
				var result = [];
				querySnapshot.docs.forEach((doc) =>
					result.push({ id: doc.id, value: doc.data() })
				);
				// console.log(result);
				return result;
			});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

function fetchProfileData(category, clear) {
	return Promise.all([
		// getArtistsByCategory(category, clear),
		getArtistByPriority(category),
		AuthenticationService.isAuthenticated()
			? userService.getFavoritesByUserId()
			: [],
	]).then(([artist, favourites]) => {
		return { artist, favourites };
	});
}

function fetchProfileDataAll() {
	return Promise.all([
		// getArtistsByCategory(category, clear),
		getAllArtists(),
		AuthenticationService.isAuthenticated()
			? userService.getFavoritesByUserId()
			: [],
	]).then(([artist, favourites]) => {
		return { artist, favourites };
	});
}

async function getArtistByTop(category) {
	let resultLocal = [];
	try {
		return new Promise((resolve) => {
			// Get data from localStorage
			resultLocal = getLocalStorage(category);
			// Test if the data from localStorage is null
			resultLocal = [];
			// console.log(resultLocal);
			// First clean the localStorage
			cleanLocalStorage();
			// Get data from FireStore database
			resultLocal = artistsRef
				.where("category", "==", category)
				.where("priority", "==", 1)
				.get()
				.then((querySnapshot) => {
					let result = [];
					querySnapshot.docs.forEach((doc) => {
						if (doc.data().category === category) {
							result.push({ id: doc.id, value: doc.data() });
						}
					});
					loadLocalStorage(category, JSON.stringify(resultLocal));
					return result;
				})
				.catch((error) => console.log(error));
			setTimeout(() => {
				resolve(resultLocal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getArtistByPriority(category) {
	let resultLocal = [];
	try {
		return new Promise((resolve) => {
			// Get data from localStorage
			resultLocal = getLocalStorage(category);
			// Test if the data from localStorage is null
			resultLocal = [];
			// console.log(resultLocal);
			// First clean the localStorage
			cleanLocalStorage();
			// Get data from FireStore database
			resultLocal = artistsRef
				.where("category", "==", category)
				.where("priority", "<=", 6)
				.get()
				.then((querySnapshot) => {
					let result = [];
					querySnapshot.docs.forEach((doc) => {
						if (doc.data().category === category) {
							result.push({ id: doc.id, value: doc.data() });
						}
					});
					loadLocalStorage(category, JSON.stringify(resultLocal));
					return result;
				})
				.catch((error) => console.log(error));
			setTimeout(() => {
				resolve(resultLocal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getAllArtists() {
	let resultLocal = [];
	try {
		return new Promise((resolve) => {
			// Get data from localStorage
			// Test if the data from localStorage is null
			resultLocal = [];
			// console.log(resultLocal);
			// First clean the localStorage
			// cleanLocalStorage();
			// Get data from FireStore database
			resultLocal = artistsRef
				.get()
				.then((querySnapshot) => {
					let result = [];
					querySnapshot.docs.forEach((doc) => {
						result.push({ id: doc.id, value: doc.data() });
					});
					return result;
				})
				.catch((error) => console.log(error));
			setTimeout(() => {
				resolve(resultLocal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

async function getArtistsByCategory(category) {
	// console.log("fetch artist...");
	let resultLocal = [];
	try {
		return new Promise((resolve) => {
			// Get data from localStorage
			resultLocal = getLocalStorage(category);
			// Test if the data from localStorage is null
			// if((resultLocal === undefined || resultLocal.length === 0)) {
			// console.log("Remote");
			resultLocal = [];
			// console.log(resultLocal);

			// First clean the localStorage
			cleanLocalStorage();

			// Get data from FireStore database
			resultLocal = artistsRef
				.where("category", "==", category)
				.get()
				.then((querySnapshot) => {
					let result = [];
					querySnapshot.docs.forEach((doc) => {
						if (doc.data().category === category) {
							result.push({ id: doc.id, value: doc.data() });
						}
					});
					// console.log(result);

					// Load the data from Firestore in localStorage
					loadLocalStorage(category, JSON.stringify(resultLocal));
					return result;
				})
				.catch((error) => console.log(error));
			// }
			setTimeout(() => {
				// console.log("fetched artist: ", category);
				// console.log(resultLocal);

				resolve(resultLocal);
			}, 2000);
		});
	} catch (error) {
		console.log("Catch error Start");
		console.log(error);
	}
}

const create = (data) => {
	return db.add(data);
};

const update = (id, value) => {
	return db.doc(id).update(value);
};

const remove = (id) => {
	return db.doc(id).delete();
};

const loadLocalStorage = (title, result) => {
	if (title) {
		switch (title) {
			case "sports":
				// console.log("Load sports")
				window.localStorage.setItem(ArtistConts.ARTIST_SPORTS, result);
				break;
			case "instagram":
				// console.log("Load instagram")
				window.localStorage.setItem(
					ArtistConts.ARTIST_INSTAGRAM,
					result
				);
				break;
			case "youtube":
				// console.log("Load youtube")
				window.localStorage.setItem(ArtistConts.ARTIST_YOUTUBE, result);
				break;
			case "acteurs/actrices":
				// console.log("Load acteurs/actrices")
				window.localStorage.setItem(ArtistConts.ARTIST_ACTEUR, result);
				break;
			case "musique":
				// console.log("Load musique")
				window.localStorage.setItem(ArtistConts.ARTIST_MUSIQUE, result);
				break;
		}
	}
};

function getLocalStorage(title) {
	if (title) {
		switch (title) {
			case "sports":
				// console.log("Get sports")
				// console.log(JSON.parse(window.localStorage.getItem(ArtistConts.ARTIST_SPORTS)))
				return JSON.parse(
					window.localStorage.getItem(ArtistConts.ARTIST_SPORTS)
				);
			case "instagram":
				// console.log("Get instagram")
				// console.log(JSON.parse(window.localStorage.getItem(ArtistConts.ARTIST_INSTAGRAM)))
				return JSON.parse(
					window.localStorage.getItem(ArtistConts.ARTIST_INSTAGRAM)
				);
			case "youtube":
				// console.log("Get youtube")
				// console.log(JSON.parse(window.localStorage.getItem(ArtistConts.ARTIST_YOUTUBE)))
				return JSON.parse(
					window.localStorage.getItem(ArtistConts.ARTIST_YOUTUBE)
				);
			case "acteurs/actrices":
				// console.log("Get acteurs/actrices")
				// console.log(JSON.parse(window.localStorage.getItem(ArtistConts.ARTIST_ACTEUR)))
				return JSON.parse(
					window.localStorage.getItem(ArtistConts.ARTIST_ACTEUR)
				);
			case "musique":
				// console.log("Get musique")
				// console.log(JSON.parse(window.localStorage.getItem(ArtistConts.ARTIST_MUSIQUE)))
				return JSON.parse(
					window.localStorage.getItem(ArtistConts.ARTIST_MUSIQUE)
				);
		}
	}
}

const cleanLocalStorage = (title) => {
	if (title) {
		switch (title) {
			case "sports":
				// console.log("removeItem sports")
				window.localStorage.removeItem(ArtistConts.ARTIST_SPORTS);
				break;
			case "instagram":
				// console.log("removeItem instagram")
				window.localStorage.removeItem(ArtistConts.ARTIST_INSTAGRAM);
				break;
			case "youtube":
				// console.log("removeItem youtube")
				window.localStorage.removeItem(ArtistConts.ARTIST_YOUTUBE);
				break;
			case "acteurs/actrices":
				// console.log("removeItem acteurs/actrices")
				window.localStorage.removeItem(ArtistConts.ARTIST_ACTEUR);
				break;
			case "musique":
				// console.log("removeItem musique")
				window.localStorage.removeItem(ArtistConts.ARTIST_MUSIQUE);
				break;
		}
	}
};

const artistsService = {
	getAll,
	getArtistsByCategory,
	getArtistsById,
	getLocalStorage,
	cleanLocalStorage,
	getArtistsBySearchValue,
	fetchProfileData,
	getFavouritesByUserId,
	getArtistByPriority,
	getArtistByTop,
	create,
	update,
	remove,
	fetchProfileDataAll,
	getAllArtists
};

export default artistsService;
