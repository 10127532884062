import React, {useState, useEffect} from 'react';
import firestoreService from '../../services/users-service';

const Review = (props) => {
    const [userReview, setUserReview] = useState(null);

    useEffect(() => {
        HandleUserReview();
    }, [props.review])

    async function HandleUserReview() {
        try {
            let result = await firestoreService.getUserByIdUnique(props.review.value.userID);
            if (result) {
                setUserReview(result);
            }
        } catch (rejectedValue) {
            // …
        }
    }

    return (
        <>
            <span className="review-from ng-star-inserted">Message</span>
            <div className="messageReview"><span className="reviews-from">{props.review.value.message}</span></div>
            <hr/>
            <span className="review-from ng-star-inserted">Note</span>
            <div className="messageReview"><span className="reviews-from">{props.review.value.note}</span></div>
        </>
    );
};

export default Review;
