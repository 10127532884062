/* eslint-disable react-hooks/rules-of-hooks */
import React, {useState, useEffect} from "react";
import Detail from "../components/ScreenDetails/detail";
import ScreenDetails from "../components/ScreenDetails/ScreenDetails";
import FooterSection from "../components/FooterSection/Footer";
import Headerd from "../components/HeaderSection/Headerd";
import {useParams} from "react-router-dom";
import artistsService from "../services/artists-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArtitstVideos from "../components/ScreenDetails/ArtitstVideos";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Headerc from "../components/HeaderSection/Headerc";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

const Details = (props) => {
    const [spacing, setSpacing] = React.useState(2);
    const classes = useStyles();

    let {id} = useParams();
    let {category} = useParams();
    const [search, setSearch] = useState("");
    const [artist, setArtist] = useState({loading: false, repos: null});
    const promise = artistsService.getArtistsById(id);
    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        setSearch("");
        if (artist.repos === null) {
            promise.then((dataResult) => {
                console.log("WelcomeDetail: ", dataResult);
                setArtist({loading: true, repos: dataResult});
            });
        }

        progressSpinner();
    }, [artist]);

    const progressSpinner = () => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            );
        }, 800);

        return () => {
            clearInterval(timer);
        };
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
                {artist.loading ? (
                    <div>
                        <div id="scrollUp" title="Scroll To Top">
                            <i className="fas fa-arrow-up"/>
                        </div>
                        <div className="mainDetail" style={{overflow: "hidden"}}>
                            <Headerd
                                searchValue={search}
                                handleSearch={handleSearch}
                                imageData={"/img/logo-white.png"}
                            />
                            <Grid container className={classes.root} spacing={1}>
                                <Grid item xs={12}>
                                    {artist.loading ? (
                                        <Detail artist={artist.repos} id={id}/>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <ScreenDetails artist={id} category={category}/>
                                    {/* <ArtitstVideos artist={id} /> */}
                                </Grid>
                                <FooterSection/>
                            </Grid>
                        </div>
                    </div>
                ) : (
                    <span id="progressSpinner">
					<CircularProgress variant="determinate" value={progress}/>
				</span>
                )}
            </Grid>
        </Grid>
    );
};

export default Details;
