import {
  CREATE_ARTIST,
  RETRIEVE_ARTISTS,
  UPDATE_ARTIST,
  DELETE_ARTIST,
  DELETE_ALL_ARTISTS,
} from "../actions/types";

const initialState = [];

function artistReducer(artists = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ARTIST:
      return [...artists, payload];

    case RETRIEVE_ARTISTS:
      return payload;

    case UPDATE_ARTIST:
      return artists.map((artist) => {
        if (artist.id === payload.id) {
          return {
            ...artist,
            ...payload,
          };
        } else {
          return artist;
        }
      });

    case DELETE_ARTIST:
      return artists.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_ARTISTS:
      return [];

    default:
      return artists;
  }
};

export default artistReducer;