// import { AuthenticationResult } from "./authenticationResult";
import { LoginConsts } from "./login-consts";
import { FavouritesConsts } from '../../Favorite/FavouriteConst';
export class AuthenticationService {

    public static authenticate(login: any)  : void {
            
        console.log("login success - AuthenticationService ");
        console.log("user", login);
        console.log("user", login.uid);

        AuthenticationService.setUserId(login.uid);
        window.localStorage.setItem(LoginConsts.ACCESS_TOKEN_KEY, login.refreshToken);
        window.localStorage.setItem(LoginConsts.USERNAME, JSON.stringify(login));
    }

    public static isAuthenticated() : boolean {
        return window.localStorage.getItem(LoginConsts.ACCESS_TOKEN_KEY) != null;
    }

    public static getUserId() : string | null {
        return window.localStorage.getItem(LoginConsts.USER_ID);
    }

    public static getUsername() : string | null {
        return window.localStorage.getItem(LoginConsts.USERNAME_KEY);
    }

    public static getAccessToken() : string | null {
        return window.localStorage.getItem(LoginConsts.ACCESS_TOKEN_KEY);
    }

    public static logOff() : void {
        localStorage.clear()
    }

    public static setUserId(userId: string) : void {
        window.localStorage.setItem(LoginConsts.USER_ID, userId);
    }

    public static setFavouritesOfUserIds(favourites: any) : void {
        window.localStorage.setItem(FavouritesConsts.Favourites, JSON.stringify(favourites));
    }

    public static getFavouritesOfUserIds() : any {
        var result = window.localStorage.getItem(FavouritesConsts.Favourites);
        return JSON.parse(result || "");
    }
}