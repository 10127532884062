import React, { useState, useEffect } from "react";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { retrieveArtists } from "../../store/actions/action";
import "./ScreenSport.css";
import artistsService from "../../services/artists-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardBlogHeader from "./Header";
import { Link } from "react-router-dom";

const Screen = (props) => {
	const promise = artistsService.fetchProfileData(props.categorie, true);

	const [dataFromService, setDataFromService] = useState({
		loading: false,
		repos: [],
	});
	const [data, setData] = useState(null);
	const [initData, setinitData] = useState({
		loading: true,
		repos: props.initData,
	});
	const [favorites, SetFavorites] = useState(null);
	const [progress, setProgress] = React.useState(0);

	const artists = useSelector((state) => state.artists);
	const dispatch = useDispatch();

	useEffect(() => {
		// console.log("Get data from initDatabase in Component Screen - ", props.initData.title);
		dispatch(retrieveArtists(props.initData.title));
		if (
			data === null ||
			favorites === null ||
			data === undefined ||
			favorites === undefined
		) {
			promise.then((dataResult) => {
				console.log("DR", dataResult);
				setData(dataResult.artist);
				SetFavorites(dataResult.favourites);
			});
		}
		// console.log(data);
		// console.log(favorites);
		if (
			data !== null &&
			data !== undefined &&
			data.length > 0 &&
			favorites !== null &&
			favorites !== undefined /*&& favorites.length > 0*/
		) {
			var result = [];
			data.forEach((doc) => {
				// console.log(favorites)
				// console.log(favorites)
				if (favorites.includes(doc.id)) {
					result.push({
						id: doc.id,
						value: doc.value,
						favorite: true,
					});
				} else {
					result.push({
						id: doc.id,
						value: doc.value,
						favorite: false,
					});
				}
				return doc.data;
			});
			setDataFromService({ loading: true, repos: result });
		}

		// console.log(artists);
		// console.log(favorites);
		progressSpinner();
	}, [data, favorites]);

	const progressSpinner = () => {
		const timer = setInterval(() => {
			setProgress((prevProgress) =>
				prevProgress >= 100 ? 0 : prevProgress + 10
			);
		}, 800);

		return () => {
			clearInterval(timer);
		};
	};

	return (
		<section id="profile-section-2" className="profile-section-2">
			{initData.loading ? (
				<div className="containerView">
					<CardBlogHeader initData={initData} />
					<div id="containerViewIn" className="containerViewIn">
						<div
							id="containerViewPost"
							className="containerViewPost"
						>
							<div
								id="containerViewPost1"
								className="containerViewPost1"
							>
								<div
									id="containerViewPost2"
									className="containerViewPost2"
								>
									<div
										id="containerViewPost3"
										className="containerViewPost3 mt-4"
									>
										{dataFromService.loading ? (
											dataFromService.repos.map((elt) => {
												return (
													<Card
														key={elt.id}
														artist={elt}
														{...props}
													/>
												);
											})
										) : (
											<span id="progressSpinner">
												<CircularProgress
													variant="determinate"
													value={progress}
												/>
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			) : (
				" "
			)}
		</section>
	);
};

export default Screen;
