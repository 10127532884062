import React, {useState, useEffect} from "react";
import ModalVideo from "react-modal-video";
import FooterSection from "../FooterSection/Footer";
import Headerd from "../HeaderSection/Headerd";
import requestsService from "../../services/request-service";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Player from "./reactPlayer";

const useStyles = makeStyles((theme) => ({
    /* root: {
        flexGrow: 1,
    }, */
    root: {
        width: "100%",
        // maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline",
    },
}));

const Requests = (props) => {
    const promise = requestsService.getAll();
    const [data, setData] = useState({loading: false, repos: null});
    const [progress, setProgress] = useState(0);
    const [search, setSearch] = useState("");
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        if (data.repos === null || data.repos === undefined) {
            promise.then((dataResult) => {
                console.log("Wussp", dataResult);
                setData({loading: true, repos: dataResult});
            });
        }
        progressSpinner();
    }, [data.repos, promise]);

    const progressSpinner = () => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            );
        }, 800);

        return () => {
            clearInterval(timer);
        };
    };

    const onOpenModal = () => {
        setOpen(!isOpen);
    };

    let history = useHistory();

    const handleSearch = (event) => setSearch(event.target.value);

    const classes = useStyles();

    return (
        <>
            <div className="mainFav" style={{overflow: "hidden"}}>
                <div className="FavPage">
                    <div id="home" className="FavPageContainer">
                        <div className="FavPageContent">
                            <div className="PageContainer">
                                <div className="FavContainer">
                                    <div className="FavCard">
                                        <div className="FavCardContainer">
                                            <div className="FavCardContent">
                                                <Grid container spacing={3}>
                                                    {data.loading ? (
                                                        data.repos.map(
                                                            (elt) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                    >
                                                                        <Player
                                                                            open={
                                                                                isOpen
                                                                            }
                                                                            toggleModal={
                                                                                onOpenModal
                                                                            }
                                                                            urlVideo={
                                                                                elt
                                                                                    .value
                                                                                    .urlVideo
                                                                            }
                                                                        />
                                                                        <List
                                                                            className={
                                                                                classes.root
                                                                            }
                                                                        >
                                                                            <ListItem alignItems="flex-start">
                                                                                <ListItemAvatar>
                                                                                    {elt
                                                                                        .value
                                                                                        .urlVideo ===
                                                                                    "" ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src="/img/time-left.png"
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            onClick={
                                                                                                onOpenModal
                                                                                            }
                                                                                            alt="Remy Sharp"
                                                                                            src="/img/play-button.png"
                                                                                        />
                                                                                    )}
                                                                                    {/* <Avatar
																						alt="Remy Sharp"
																						src={
																							elt
																								.value
																								.urlVideo ===
																							""
																								? "/img/time-left.png"
																								: "/img/play-button.png"
																						}
																					/> */}
                                                                                </ListItemAvatar>
                                                                                <ListItemText
                                                                                    primary={
                                                                                        elt
                                                                                            .value
                                                                                            .event
                                                                                    }
                                                                                    secondary={
                                                                                        <React.Fragment>
                                                                                            <Typography
                                                                                                component="span"
                                                                                                variant="body2"
                                                                                                className={
                                                                                                    classes.inline
                                                                                                }
                                                                                                color="textPrimary"
                                                                                            >
                                                                                                {
                                                                                                    elt
                                                                                                        .value
                                                                                                        .nameArtist
                                                                                                }
                                                                                            </Typography>
                                                                                            {" — " +
                                                                                            elt
                                                                                                .value
                                                                                                .description}
                                                                                        </React.Fragment>
                                                                                    }
                                                                                />
                                                                            </ListItem>
                                                                        </List>
                                                                    </Grid>
                                                                )
                                                            }
                                                        )
                                                    ) : (
                                                        <span id="progressSpinner">
															<CircularProgress
                                                                variant="determinate"
                                                                value={progress}
                                                            />
														</span>
                                                    )}
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSection/>
            </div>
        </>
    );
};

export default Requests;
