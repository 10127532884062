import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import ErrorPage from "../components/ErrorPage/404";
import details from "../themes/details";
import form from "../components/Accounts/form";
import Favorite from "./../components/Favorite/Favorite";
import ReviewsList from "./../components/Reviews/ReviewsList";
import LogAccountBookNow from "../components/Booking/LogAccount";
import AccountBookNow from "../components/Booking/Account";
import RequestBookNow from "../components/Booking/Request";
import PaymentBookNow from "../components/Booking/Payment";
import ConfirmRequest from "../components/Booking/ConfirmRequest";
import Requests from "../components/Requests/Requests";
import Peoples from "../components/Peoples/People";
import TestPages from "../components/_Tests/Test";
import MyProfile from "../components/MyProfile/Profile";

class MyRouts extends React.Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route exact path="/" component={ThemeOne}/>
                        <Route exact path="/home" component={ThemeOne}/>
                        <Route
                            path="/logaccountbooknow/:id"
                            component={LogAccountBookNow}
                        />
                        <Route
                            path="/accountbooknow/:id"
                            component={AccountBookNow}
                        />
                        <Route
                            path="/requestbooknow/:id"
                            component={RequestBookNow}
                        />
                        <Route
                            path="/ConfirmRequest/:name/:urlphoto"
                            component={ConfirmRequest}
                        />
                        <Route
                            path="/paymentbooknow"
                            component={PaymentBookNow}
                        />
                        <Route path="/favorite" component={Favorite}/>
                        <Route path="/requests" component={Requests}/>
                        <Route path="/my-account" component={MyProfile}/>
                        <Route path="/peoples/:category" component={Peoples}/>
                        <Route path="/reviews/:id" component={ReviewsList}/>
                        <Route
                            exact
                            path="/details/:id/:category"
                            component={details}
                        />
                        <Route path="/login" component={Login}/>
                        <Route path="/test" component={TestPages}/>
                        <Route path="/form" component={form}/>
                        <Route path="/signup" component={Signup}/>
                        <Route path="/forgot" component={Forgot}/>
                        <Route path="/error-page" component={ErrorPage}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default MyRouts;
