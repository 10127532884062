import React from "react";
import { Link } from "react-router-dom";

const ModeDisconnected = () => {
	return (
		<>
			
			<li className="in3">
				<b>
					<Link className="nav-link " to="/login">
						Comment ca marche?
					</Link>
				</b>
			</li>
			<li className="in2">
				<b>
					<Link className="nav-link " to="/login">
						Contact
					</Link>
				</b>
			</li>
			<li className="in1">
				<b>
					<Link
						className="nav-link "
						style={{ color: "white" }}
						to="/login"
					>
						Se connecter
					</Link>
					<hr className="bar1" />
				</b>
			</li>
		</>
	);
};

export default ModeDisconnected;
