import React, { useState, useEffect } from 'react';
import Header from '../HeaderSection1/Header';
import Footer from '../FooterSection/Footer';
import { useParams } from "react-router-dom";
import Headerd from "../HeaderSection/Headerd";

const initDataLoad = {
    formText: "Don't have an account?",
    btnText: "J'ai compris",
}

const ConfirmRequest = props => {
    const [initData, setInitData] = useState([]);
    const [artist, setArtist] = useState({ loading: false, repos: null });
    const [search, setSearch] = useState("");

    useEffect(() => {
        setInitData(initDataLoad);
        var artist_request = JSON.parse(window.localStorage.getItem("artist_request"));

        setArtist({ loading: true, repos: artist_request });
        console.log('urlphoto: ', artist_request);
    }, [initDataLoad])

    const onSubmit = () => props.history.push(`/`);

    const handleSearch = (event) => setSearch(event.target.value);

    return (
      <div className="container">
        <div className="d-flex flex-column align-items-center justify-content-center">
        <Headerd
          searchValue={search}
          handleSearch={handleSearch}
          imageData={"/img/logo-white.png"}
        />
          <section
            className="h-100vh d-flex align-items-center justify-content-center"
          >
              <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-12 confirmSection">
                                <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg move ">
                                    <div className="contact-top">
                                        <div className="ImageNameCountry1">
                                            {
                                                artist.loading &&
                                                <img
                                                    src={artist?.repos.urlPicture}
                                                    alt=""
                                                    className="ImageArtist"
                                                />
                                            }
                                        </div>
                                        {
                                            artist.loading &&
                                            <h3 className="contact-title mt-1">{artist.repos.name}</h3>
                                        }
                                    </div>
                                    <div id="confirmRequest" className="row">
                                        <div className="col-12">
                                            <p>Votre demande va être prise en compte.</p>
                                            <p>On reviendra vers vous très rapidement.</p>
                                            <p>La team Only2U</p>
                                        </div>

                                        <div className="col-12">
                                            <button className="btn btn-bordered w-100 mt-3 mt-sm-4" type="submit" onClick={onSubmit}>{initData.btnText}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </section>
        </div>
      </div>
    );
}

export default ConfirmRequest;
