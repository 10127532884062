import React, { useEffect, useState } from 'react';
import FooterSection from "../FooterSection/Footer";
import Headerd from "../HeaderSection/Headerd";
import Reviews from './Reviews';
import { useParams } from "react-router-dom";
import reviewsService from './../../services/reviews-service';

const ReviewsList = (props) => {
    const [reviewLists, setReviewLists] = useState({ loading: false, repos: null });
    const [search, setSearch] = useState("");
    let { id } = useParams();

    useEffect(() => {
        console.log("Component ReviewsList ");
        HandleReviewsList()
    }, []);

    async function HandleReviewsList() {
        console.log("API HandleReviewsList");
        try {
            let result = await reviewsService.getAll();
            if (result) {
                let reviews_list = []
                result.map((elt) => {
                    if (elt.id === id) {
                        reviews_list.push(elt);
                    }
                });
                setReviewLists({ loading: true, repos: reviews_list });
            }
        }
        catch (rejectedValue) {
            // �
        }
    }

    const handleSearch = (event) =>
        setSearch(event.target.value);

    return (
        <div>
            { /*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            { /*====== Scroll To Top Area End ======*/}
            <div className="main">
                <Headerd
                    searchValue={search}
                    handleSearch={handleSearch}
                    imageData={"/img/logo-white.png"}
                />
                {
                    reviewLists.loading ?
                        (<Reviews reviews={reviewLists.repos} {...props} />) : ("")
                }
                <FooterSection />
            </div>
        </div>
    );
};

export default ReviewsList;