import React, {useState, useEffect, Component} from "react";
import ModeConnected from './ModeConnected';
import ModeDisconnected from './ModeDisconnected';
import {AuthenticationService} from './../Accounts/authentication/authentication-service';
import SearchDashboard from './../SearchDashboard/SearchDashboard';
import db from "../../config/auth-firebase";
import {Link} from 'react-router-dom';

class Headerd extends Component {
    render() {
        return (
            <>
                <header className="navbar navbar1 navbar-expand-lg navbar-dark">
                    <div className="container position-relative">
                        <Link to="/home">
                            <img
                                src={this.props.imageData}
                                alt="brand-logo"
                                className="goodHC"
                            />
                            <img
                                className="navbar-brand-sticky"
                                src="/img/logo.png"
                                alt="sticky brand-logo"
                            />
                        </Link>
                        <div className="navbar-inner">
                            <nav>
                                <div className="collapsex navbar-collapsxe" id="navbarSupportedContent">
                                    <ul className="navbar-nav" id="navbar-nav">
                                        {!AuthenticationService.isAuthenticated() ? (
                                            <li className="li_offline">
                                                <br/>
                                                <div>
                                                    <i
                                                        className="fa fa-search fa-lg fa-fw pi_off"
                                                        aria-hidden="true"
                                                        color="white"
                                                    >{""}</i>
                                                    <input
                                                        id="loupe"
                                                        type="text"
                                                        name="loupe"
                                                        onChange={(event) =>
                                                            this.props.handleSearch(event)
                                                        }
                                                        value={this.props.searchString}
                                                        placeholder=" Recherche"
                                                        className="transparent1"
                                                    />
                                                    <hr className="bar2d"/>
                                                </div>
                                            </li>
                                        ) : (
                                            <li>
                                                <br/>
                                                <div className="my-search-bar">
                                                    <i
                                                        className="fa fa-search fa-lg fa-fw pi"
                                                        aria-hidden="true"
                                                        color="white"
                                                    >{""}</i>
                                                    <input
                                                        id="loupe"
                                                        type="text"
                                                        name="loupe"
                                                        value={this.props.searchString}
                                                        placeholder=" Recherche"
                                                        className="transparent1"
                                                    />
                                                    <hr className="bar2"/>
                                                </div>
                                            </li>
                                        )}

                                        {AuthenticationService.isAuthenticated() ? (
                                            <ModeConnected/>
                                        ) : (
                                            <ModeDisconnected/>
                                        )}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default Headerd;
