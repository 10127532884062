import React, {Component} from "react";
import db from "../../config/auth-firebase";
import {Link} from "react-router-dom";
import "./ScreenDetails.css";
import requestsService from "../../services/request-service";
import Grid from "@material-ui/core/Grid";

const artistsRef = db.collection("artists");
const requestsRef = db.collection("requests");

const initData = {
    iconClass: "far fa-lightbulb text-primary mr-1",
    preHeading: "",
    preHeadingspan: "",
    heading: "Vous pourriez aussi aimer",
    headingText: "",
    headingTexttwo: "",
};

class ScreenDetails extends Component {
    state = {
        initData: {},
        repos: [],
        videos: [],
        loading: false,
        /*data: []*/
    };

    componentDidMount() {
        this.setState({
            initData: initData,
            loading: false,
            repos: [],
            videos: [],
        });
        console.log("Component ScreenDetails");
        console.log(this.props.artist);

        artistsRef
            .where("priority", "==", 1)
            .get()
            .then((querySnapshot) => {
                var result = [];
                querySnapshot.docs.map((doc) =>
                    result.push({id: doc.id, value: doc.data()})
                );
                this.setState({
                    initData: initData,
                    loading: true,
                    repos: result,
                    videos: []
                });
            });

        requestsRef
            .where("artistID", "==", this.props.artist)
            .where("isPublic", "==", true)
            .get()
            .then((querySnapshot) => {
                var result = [];
                querySnapshot.docs.map((doc) =>
                    result.push({id: doc.id, value: doc.data()})
                );
                this.setState({
                    initData: initData,
                    loading: true,
                    videos: result,
                });
            });
    }

    render() {
        return (
            <>
                <section
                    id="screenshotsDetail"
                    className="section screenshots-area"
                >
                    {this.state.videos.length > 0 ? <hr className="bart"/> : " "}
                    <div className="container contain">
                        {(this.state.videos.length > 0)
                            ? (
                                <>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-10 col-lg-7">
                                            <div className="section-heading text-center">
                                                <span className="d-inline-block rounded-pill mt-4  shadow-sm fw-5 px-4 py-2 mb-3">
                                                    <i
                                                        className={
                                                            this.state.initData.iconClass
                                                        }
                                                    />
                                                    <span className="text-primary">
                                                        {this.state.initData.preHeading}
                                                    </span>{" "}
                                                    {this.state.initData.preHeadingspan}
                                                </span>
                                                <h2 className="text-capitalize">
                                                    Mes Vidéos
                                                </h2>
                                                <p className="d-none d-sm-block mt-4">
                                                    {this.state.initData.headingText}
                                                </p>
                                                <p className="d-block d-sm-none mt-4">
                                                    {this.state.initData.headingTexttwo}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="containerViewInx" className="containerViewInx">
                                        <div
                                            id="containerViewPostx"
                                            className="containerViewPostx"
                                        >
                                            <div
                                                id="containerViewPost1x"
                                                className="containerViewPost1x"
                                            >
                                                <div
                                                    id="containerViewPost2x"
                                                    className="containerViewPost2x"
                                                >
                                                    <div
                                                        id="containerViewPost3x"
                                                        className="containerViewPost3x"
                                                    >
                                                        <Grid container spacing={1}>
                                                            {this.state.loading
                                                                ? this.state.videos.map(
                                                                    (elt, index) => {
                                                                        return (
                                                                            <Grid xs={12} sm={3} key={index}>
                                                                                <video
                                                                                    id="videoDetail"
                                                                                    className="videoDetail"
                                                                                    src={elt.value.urlVideo}
                                                                                    controls
                                                                                >
                                                                                    {" "}
                                                                                </video>
                                                                            </Grid>
                                                                        );
                                                                    }
                                                                )
                                                                : " "}
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : " "}
                        <hr className="bart"/>
                        {/* <div className="container contain"> */}
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-7">
                                <div className="section-heading text-center">
								<span className="d-inline-block rounded-pill mt-4  shadow-sm fw-5 px-4 py-2 mb-3">
									<i
                                        className={
                                            this.state.initData.iconClass
                                        }
                                    />
									<span className="text-primary">
										{this.state.initData.preHeading}
									</span>{" "}
                                    {this.state.initData.preHeadingspan}
								</span>
                                    <h2 className="text-capitalize">
                                        {this.state.initData.heading}
                                    </h2>
                                    <p className="d-none d-sm-block mt-4">
                                        {this.state.initData.headingText}
                                    </p>
                                    <p className="d-block d-sm-none mt-4">
                                        {this.state.initData.headingTexttwo}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="containerViewIn" className="containerViewIn">
                            <div
                                id="containerViewPost"
                                className="containerViewPost"
                            >
                                <div
                                    id="containerViewPost1"
                                    className="containerViewPost1"
                                >
                                    <div
                                        id="containerViewPost2"
                                        className="containerViewPost2"
                                    >
                                        <div
                                            id="containerViewPost3"
                                            className="containerViewPost3"
                                        >
                                            {this.state.loading
                                                ? this.state.repos.map(
                                                    (elt, index) => {
                                                        return (
                                                            <div
                                                                id={elt.id}
                                                                key={elt.id}
                                                                className="hentry"
                                                            >
                                                                <div className="talent-element-1">
                                                                    <div className="talent-element-2">
                                                                        <Link
                                                                            className="talent-container"
                                                                            to={`/details/${elt.id}/${elt.value.category}`}
                                                                        >
                                                                            <div className="priceFav">
                                                                                <div className="talent-price-container">
                                                                                    EUR{" "}
                                                                                    {
                                                                                        elt
                                                                                            .value
                                                                                            .price
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="talent-image-container"
                                                                                style={{backgroundImage: `url(${elt.value.urlPicture})`}}
                                                                            >{""}</div>

                                                                            <div className="talent-content-container">
                                                                                <div className="talent-info-container">
                                                                                    <div className="talent-infos">
                                                                                        <div className="talent-nom">
                                                                                            {
                                                                                                elt
                                                                                                    .value
                                                                                                    .lastname
                                                                                            }
                                                                                        </div>
                                                                                        <div className="talent-title">
                                                                                            {
                                                                                                elt
                                                                                                    .value
                                                                                                    .title
                                                                                            }
                                                                                        </div>
                                                                                        <div className="talent-star">
                                                                                            <i className="fas fa-star text-warning"/>
                                                                                            <i className="fas fa-star text-warning"/>
                                                                                            <i className="fas fa-star text-warning"/>
                                                                                            <i className="fas fa-star text-warning"/>
                                                                                            <i className="fas fa-star text-warning"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )
                                                : " "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ScreenDetails;
