import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import Headerd from "../HeaderSection/Headerd";
import FooterSection from "../FooterSection/Footer";
import Header from "../HeaderSection/Header";
import Footer from "../FooterSection/Footer";
import {useParams, useHistory} from "react-router-dom";
import RequestOnly from "./RequestOnly";
import artistsService from "../../services/artists-service";
import './booking.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import {eventsData} from "../../config/events";
import {eventsPro} from "../../config/events_pro";
import requestsService from "../../services/request-service";
import {LoginConsts} from "../Accounts/authentication/login-consts";
import firebase from "firebase";
import "firebase/firestore";

const initDataLoad = {
    formHeading: "Nouvelle demande ",
    formContent: "Demande Video",
    formText: "Vous n'avez pas de compte?",
    btnText: "Envoyer la Demande",
    btnText_2: "S'enregistrer",
    title_select: "Sélectionner l'événement",
    subtitle_select: "Pour quel événement",
    title_instruction: "Ajouter des instructions",
    subtitle_instruction: "Préciser votre demande",
    title_numero: "Ajouter le numéro de téléphone",
    subtitle_numero: "Vous serez contacter sur ce numéro",
    title_name: "Saisir le nom",
    subtitle_name: "A qui offrez-vous cette demande?",
};

const RequestBookNow = (props) => {
    let {id} = useParams();
    const [initData, setInitData] = useState([]);
    const [events, setEvents] = useState([]);
    const [progress, setProgress] = React.useState(0);
    const promise = artistsService.getArtistsById(id);
    const [showDivSend, setShowDivSend] = useState(true);
    // const [request, setRequest] = useState<Request>(null);
    const [search, setSearch] = useState("");
    const [showDivRequest, setShowDivRequest] = useState(false);
    const [showDivMeName, setShowDivMeName] = useState(false);
    const [artist, setArtist] = useState({loading: false, repos: null});

    const [name, setName] = useState("");
    const [evenement, setEvenement] = useState("");
    const [instruction, setInstruction] = useState("");
    const [phone, setPhone] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const forEntreprise = location.state;

    const handleSearch = (event) => setSearch(event.target.value);

    useEffect(() => {
        setInitData(initDataLoad);
        setEvents(props.events);
        initDataLoad.forEntreprise = forEntreprise;
        if (artist.repos === null) {
            promise.then((dataResult) => {
                console.log("WelcomeRequest: ", dataResult);
                setArtist({loading: true, repos: dataResult});
                window.localStorage.setItem(
                    "artist_request",
                    JSON.stringify(dataResult)
                );
            });
        }

        //progressSpinner();
    }, [artist, promise]);

    const onSubmit = () => {
        /* console.log("name: ", name);
        console.log("evenement: ", evenement);
        console.log("instruction: ", instruction);
        console.log("phone: ", phone);
        console.log("isPublic: ", isPublic); */
        setArtist({loading: false});
        setIsSubmitted(true);
        // progressSpinner();

        // Formater l'objet recupérer depusi la section
        var user = JSON.parse(
            window.localStorage.getItem(LoginConsts.USERNAME)
        );

        const request = {
            artistID: id,
            createdTime: firebase.firestore.Timestamp.fromDate(new Date()),
            description: instruction,
            event: evenement,
            isPublic: isPublic,
            to: name,
            phone: phone,
            price: artist.repos.price,
            state: "0",
            nameArtist: artist.repos.name,
            from: "",
            urlVideo: "",
            userID: user.uid,
        };
        console.log("request: ", request);
        // console.log("------------YYYYYYYYyyyyyyy------------");
        /* if (forEntreprise) {
            var result = requestsService.create(request);
            result.then((dataResult) => {
                console.log("result: ", dataResult);
                props.history.push(
                    `/ConfirmRequest/${artist.repos.name}/${artist.repos.urlPicture}`
                );
            });
        } else {
            // console.log("------------XXXxxxxxxxXXXXXxxxx------------");
            // history.push("/paymentbooknow");
            var result = requestsService.create(request);
            result.then((dataResult) => {
                console.log("result: ", dataResult);
                props.history.push(
                    `/ConfirmRequest/${artist.repos.name}/${artist.repos.urlPicture}`
                );
            });
        } */
        var result = requestsService.create(request);
            result.then((dataResult) => {
                console.log("result: ", dataResult);
                props.history.push(
                    `/ConfirmRequest/${artist.repos.name}/${artist.repos.urlPicture}`
                );
            });
    };

    const handleShowDivSend = () => {
        setShowDivSend(true);
        setShowDivRequest(false);
    };

    const handleShowDivRequest = () => {
        setShowDivSend(false);
        setShowDivRequest(true);
    };

    const handleShowDivMe = () => setShowDivMeName(false);

    const handleShowDivAnother = () => setShowDivMeName(true);

    const progressSpinner = () => {
        // console.log("For entreprise", forEntreprise);
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            );
        }, 800);

        return () => {
            clearInterval(timer);
        };
    };

    return (
      <div className="container-fluid">
        <Headerd
          searchValue={search}
          handleSearch={handleSearch}
          imageData={"/img/logo-white.png"}
        />
        {/* <Header imageData={"/img/logo-white.png"}/> */}

        <div className="container row">
          <div className="col-12 pt-5 mt-xs-5">
            {isSubmitted ? (
              <span id="progressSpinner">
                <CircularProgress variant="determinate" value={progress} />
              </span>
            ) : ""}

            {artist.loading ? (
              <>
              <div className="containerxxx mt-xs-5 pt-sm-5">
                  <div className="d-flex align-items-center justify-content-center pt-xs-5 mt-xs-5">
                      <div className="welcomeSectionx pt-xs-5">
                          <div className="HeadState mt-xs-5">
                            <span className="font-weight-bold h6">
                              1. Compte
                            </span>
                            <span className="font-weight-bold mx-3 h6 text-primary">
                              2. Demande
                            </span>
                            <span className="font-weight-bold h6">
                              3. Validation
                            </span>
                          </div>
                          <div className="contact-boxx bg-white w-100 text-center rounded p-4 pt-0 p-sm-5 mt-0 mt-lg-0 shadow-lg move ">
                              <div className="contact-topx">
                                  <h3 className="contact-title mb-1">
                                      {initData.formHeading}
                                  </h3>
                                  <div className="ImageNameCountry1">
                                      <img
                                          src={
                                              artist.repos
                                                  .urlPicture
                                          }
                                          alt=""
                                          className="ImageArtist"
                                      />
                                      <br />
                                      <h4>{artist.repos.name}</h4>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-12">
                                      <div className="envoyerVideo mt-2">
                                        <div
                                          className="form-group"
                                          d="blockChoice"
                                              style={{
                                                  display:
                                                      forEntreprise
                                                          ? "none"
                                                          : "initial",
                                              }}
                                          >
                                              <div className="form-check my-4 form-check-inline">
                                                  <input
                                                      className="form-check-input myradio"
                                                      onClick={(event) => handleShowDivMe(event)}
                                                      type="radio"
                                                      name="requestFor"
                                                      id="inlineRadio11"
                                                      value="option11"
                                                      checked={!showDivMeName}
                                                  />
                                                  <label className="form-check-label mylabel" htmlFor="inlineRadio11">
                                                      <i className="fa fa-user icone" aria-hidden="true">{""}</i>
                                                      <span>Pour moi</span>
                                                  </label>
                                              </div>
                                              <div className="form-check form-check-inline">
                                                  <input
                                                      className="form-check-input myradio"
                                                      onClick={(event) => handleShowDivAnother(event)}
                                                      type="radio"
                                                      name="requestFor"
                                                      id="inlineRadio12"
                                                      value="option12"
                                                  />
                                                  <label
                                                      className="form-check-label mylabel"
                                                      htmlFor="inlineRadio12"
                                                  >
                                                      <i className="fa fa-gift icone" aria-hidden="true">{""}</i>
                                                      <span>Pour un proche</span>
                                                  </label>
                                              </div>
                                          </div>
                                          <div
                                              className="demandeVideo mt-3"
                                              style={{
                                                  display:
                                                      showDivMeName
                                                          ? "none"
                                                          : "initial",
                                              }}
                                          >
                                              {
                                                  <form id="contact-form">
                                                      <RequestOnly
                                                          initDataLoad={
                                                              initData
                                                          }
                                                          events={
                                                              forEntreprise
                                                                  ? eventsPro
                                                                  : eventsData
                                                          }
                                                          handleEvenement={
                                                              setEvenement
                                                          }
                                                          handleInstruction={
                                                              setInstruction
                                                          }
                                                          handlePhone={
                                                              setPhone
                                                          }
                                                          handleIsPublic={
                                                              setIsPublic
                                                          }
                                                          handleSubmit={
                                                              onSubmit
                                                          }
                                                      />
                                                  </form>
                                              }
                                          </div>
                                          <div>
                                              <div
                                                  className="form-group"
                                                  style={{ display : showDivMeName ? "initial" : "none" }}
                                              >
                                                  <label className="text-left float-left font-weight-bold">
                                                      {" "}
                                                      {
                                                          initData.title_name
                                                      }
                                                  </label>
                                                  <div className="input-group text-left">
                                                      <input
                                                          type="text"
                                                          className="form-control"
                                                          onChange={(
                                                              event
                                                          ) =>
                                                              setName(
                                                                  event
                                                                      .target
                                                                      .value
                                                              )
                                                          }
                                                          name="instruction"
                                                          placeholder={
                                                              initData.subtitle_name
                                                          }
                                                          required="required"
                                                      />
                                                  </div>
                                              </div>
                                              {showDivMeName && (
                                                  <form id="contact-form">
                                                      <RequestOnly
                                                          initDataLoad={
                                                              initData
                                                          }
                                                          events={
                                                              eventsData
                                                          }
                                                          handleEvenement={
                                                              setEvenement
                                                          }
                                                          handleInstruction={
                                                              setInstruction
                                                          }
                                                          handlePhone={
                                                              setPhone
                                                          }
                                                          handleIsPublic={
                                                              setIsPublic
                                                          }
                                                          handleSubmit={
                                                              onSubmit
                                                          }
                                                      />
                                                  </form>
                                              )}
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-12">
                                      <button
                                          className="btn btn-bordered w-100 mt-3 mt-sm-4"
                                          type="submit"
                                          onClick={onSubmit}
                                      >
                                          {forEntreprise
                                              ? "Envoyer demande"
                                              : initData.btnText}
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </>
            ) : (
              <span id="progressSpinner">
                <CircularProgress variant="determinate" value={progress} />
              </span>
            )}
          </div>
        </div>
        {/* <FooterSection/> */}
      </div>

    );
};

export default RequestBookNow;
