import React, { useState, useEffect } from 'react';
// import Header from '../components/HeaderSection/Header';
import Headerc from '../components/HeaderSection/Headerc';
import HeroSection from '../components/HeroSection/HeroOne';
import FooterSection from '../components/FooterSection/Footer';
import Screen from '../components/ScreenCard/Screen';
import firestoreService from '../services/users-service';
import { data } from '../config/data';
import db from "../config/auth-firebase";
import artistsService from '../services/artists-service';
import './themeHome.css';
import { anonSignIn } from '../config/auth-firebase';
import firebase from 'firebase';

const artistsRef = db.collection("artists");

const ThemeOne = (props: any) => {
    const [search, setSearch] = useState("");
    const [dataSearching, setDataSearching] = useState({
        loading: false,
        repos: [{ id: "", value: {} }],
      });

    useEffect(()  => {
        anonSignIn();
        setTimeout(() => {
            console.log("Updated LocalStorage");
        }, 900000);
    }, [])
    
    const handleSearch = (event: any) => {
        setDataSearching({
            loading: false,
            repos: [],
          });
        setSearch(event.target.value);
        if (event.target.value !== "") {
            artistsRef
              .orderBy("lastname")
              .orderBy("priority", "desc")
              .startAt(event.target.value)
              .get()
              .then((querySnapshot) => {
                let result: { id: string; value: firebase.firestore.DocumentData; }[] = [];
                querySnapshot.docs.map((doc) =>
                  result.push({ id: doc.id, value: doc.data() })
                );
                console.log('RES search',result);
                setDataSearching({
                  loading: true,
                  repos: result,
                });
              });
          } else {
            setDataSearching({
              loading: false,
              repos: [],
            });
          }
    }

    const handleChangedFavorite = (event: any, id: any) => 
        firestoreService.getUserById(id, event.target.checked);
        
    return (
        <>
            {/*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            {/* ====== Scroll To Top Area End ====== */}
            <div className="main">
                <Headerc
                    searchValue={search}  
                    handleSearch={handleSearch} 
                    dataSearching={dataSearching}
                    imageData={"/img/logo-white.png"} 
                />
                <HeroSection searchValue={search} />
                <div className="subMain">
                    {
                        data.map((elt, edx) => {
                            const dataLocal = artistsService.getLocalStorage(elt.title)
                            return (
                                <Screen 
                                    handleChangedFavorite={handleChangedFavorite} 
                                    initData={elt} 
                                    data = {dataLocal}
                                    categorie = {elt.title}
                                    key={edx} 
                                    { ...props } 
                                />
                            );
                        })
                    }
                </div>
                <FooterSection />
            </div>
        </>
    );
}

export default ThemeOne;
