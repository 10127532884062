import React, { Component } from "react";
import db from "../../config/auth-firebase";
import { Link } from "react-router-dom";
import "./ScreenDetails.css";
import requestsService from "../../services/request-service";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Player from "../Requests/reactPlayer";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	/* root: {
		flexGrow: 1,
	}, */
	root: {
		width: "100%",
		// maxWidth: "36ch",
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
}));

const requestsRef = db.collection("requests");
const initData = {
	iconClass: "far fa-lightbulb text-primary mr-1",
	preHeading: "",
	preHeadingspan: "",
	heading: "Mes Vidéos",
	headingText: "",
	headingTexttwo: "",
};

class ArtitstVideos extends Component {
	state = {
		initData: {},
		/*data: []*/
	};
	componentDidMount() {
		this.setState({
			initData: initData,
			loading: false,
			repos: [],
            progress: 0, 
            setProgres: 0, 
            isOpen: false,
            setOpen: false
		});
		//        console.log("Component ScreenDetails");
		console.log("ICI----------", this.props.artist);

		requestsRef
			.where("artistID", "==", this.props.artist)
			.get()
			.then((querySnapshot) => {
				var result = [];
				querySnapshot.docs.map((doc) =>
					result.push({ id: doc.id, value: doc.data() })
				);
				this.setState({
					initData: initData,
					loading: true,
					repos: result,
				});
			});
	}
    /* classes = useStyles();
    progressSpinner = () => {
        const timer = setInterval(() => {
            this.state.setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            );
        }, 800);
    
        return () => {
            clearInterval(timer);
        };
    };
    
    onOpenModal = () => {
        this.state.setOpen(!this.state.isOpen);
     }; */

	render() {
		return (
			<section
				id="screenshotsDetail"
				className="section screenshots-area"
			>
				<hr className="bart" />
				<div className="container contain">
					<div className="row justify-content-center">
						<div className="col-12 col-md-10 col-lg-7">
							<div className="section-heading text-center">
								<span className="d-inline-block rounded-pill mt-4  shadow-sm fw-5 px-4 py-2 mb-3">
									<i
										className={
											this.state.initData.iconClass
										}
									/>
									<span className="text-primary">
										{this.state.initData.preHeading}
									</span>{" "}
									{this.state.initData.preHeadingspan}
								</span>
								<h2 className="text-capitalize">
									{this.state.initData.heading}
								</h2>
								<p className="d-none d-sm-block mt-4">
									{this.state.initData.headingText}
								</p>
								<p className="d-block d-sm-none mt-4">
									{this.state.initData.headingTexttwo}
								</p>
							</div>
						</div>
					</div>

					<div className="FavCard">
										<div className="FavCardContainer">
											<div className="FavCardContent">
												<Grid container spacing={3}>
													{this.state.loading ? (
														this.state.repos.map(
															(elt) => {
																return elt.value
																	.isPublic ? (
																	<Grid
																		item
																		xs={6}
																	>
																		<Player
																			open={
																				this.state.isOpen
																			}
																			toggleModal={
																				this.onOpenModal
																			}
																			urlVideo={
																				elt
																					.value
																					.urlVideo
																			}
																		/>
																		<List
																			className={
																				this.classes.root
																			}
																		>
																			<ListItem alignItems="flex-start">
																				<ListItemAvatar>
																					{elt
																						.value
																						.urlVideo ===
																					"" ? (
																						<Avatar
																							alt="Remy Sharp"
																							src="/img/time-left.png"
																						/>
																					) : (
																						<Avatar
																							onClick={
																								this.onOpenModal
																							}
																							alt="Remy Sharp"
																							src="/img/play-button.png"
																						/>
																					)}
																					{/* <Avatar
																						alt="Remy Sharp"
																						src={
																							elt
																								.value
																								.urlVideo ===
																							""
																								? "/img/time-left.png"
																								: "/img/play-button.png"
																						}
																					/> */}
																				</ListItemAvatar>
																				<ListItemText
																					primary={
																						elt
																							.value
																							.event
																					}
																					secondary={
																						<React.Fragment>
																							<Typography
																								component="span"
																								variant="body2"
																								className={
																									this.classes.inline
																								}
																								color="textPrimary"
																							>
																								{
																									elt
																										.value
																										.nameArtist
																								}
																							</Typography>
																							{" — " +
																								elt
																									.value
																									.description}
																						</React.Fragment>
																					}
																				/>
																			</ListItem>
																		</List>
																	</Grid>
																) : (
																	""
																);
															}
														)
													) : (
														<span id="progressSpinner">
															<CircularProgress
																variant="determinate"
																value={this.state.progress}
															/>
														</span>
													)}
												</Grid>
											</div>
										</div>
									</div>
								

                </div>
			</section>
		);
	}
}
export default ArtitstVideos;
