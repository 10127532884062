import React, {useState, useEffect, useCallback} from "react";
import FooterSection from "../FooterSection/Footer";
import Headerd from "../HeaderSection/Headerd";
import artistsService from "../../services/artists-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CardSingle from "../ScreenCard/CardSingle";
import {AuthenticationService} from "../Accounts/authentication/authentication-service";
import Checkbox from "@material-ui/core/Checkbox";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@material-ui/icons/BookmarkOutlined";
import Card from "../ScreenCard/Card";
import { retrieveAllArtists, retrieveFavArtists } from "../../store/actions/action";
import { useDispatch, useSelector } from "react-redux";
import firestoreService from "../../services/users-service";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

const Favorite = (props) => {
    // const promise = artistsService.getFavouritesByUserId();
    const promise = artistsService.fetchProfileDataAll();

    const [dataFromService, setDataFromService] = useState({loading: false, repos: []});
    const [data, setData] = useState(null);
    const [search, setSearch] = useState("");
    const [progress, setProgress] = useState(0);

    const [favorites, setFavorites] = useState(null);

    const classes = useStyles();

    const artists = useSelector((state) => state.artists);
	const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveFavArtists());
		
        if (data === null ||
			favorites === null ||
			data === undefined ||
			favorites === undefined
        ) {
            promise.then((dataResult) => {
                console.log('XXXX',dataResult);
                setData(dataResult.artist);
                setFavorites(dataResult.favourites);
            });
        }
        if (
			data !== null &&
			data !== undefined &&
			data.length > 0 &&
			favorites !== null &&
			favorites !== undefined /*&& favorites.length > 0*/
		) {
            var result = [];
			data.forEach((doc) => {
				// console.log(favorites)
				console.log('YYY',favorites)
				if (favorites.includes(doc.id)) {
					result.push({
						id: doc.id,
						value: doc.value,
						favorite: true,
					});
				} else {
					/* result.push({
						id: doc.id,
						value: doc.value,
						favorite: false,
					}); */
				}
				return doc.data;
			});
			setDataFromService({ loading: true, repos: result });
		
        }
        progressSpinner();
    }, [data, favorites]);

    const progressSpinner = () => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            );
        }, 800);

        return () => {
            clearInterval(timer);
        };
    };

    const handleSearch = (event) => setSearch(event.target.value);

    const handleChangedFavorite = (event, id) => {
        firestoreService.getUserById(id, event.target.checked);
        // window.location.reload();
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    let history = useHistory();

    return (
        <>
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up"/>
            </div>
            <div className="mainFav" style={{overflow: "hidden"}}>
                <Headerd
                    searchValue={search}
                    handleSearch={handleSearch}
                    imageData={"/img/logo-white.png"}
                />
                <div className="FavPage">
                    <div className="mat-drawer-backdrop ng-star-inserted"></div>
                    <div id="home" className="FavPageContainer">
                        <div className="FavPageContent">
                            <div className="PageContainer">
                                <div className="FavContainer">
                                    <div className="FavTitle">
                                        <div className="FavTitleContent">
                                            <div className="FavTitleContentBack">
                                                <div className="row-arrowx">
                                                    <Link
                                                        to="/"
                                                        className="par"
                                                    >
                                                        <h5
                                                            className="ButtonLeftMobile"
                                                            onClick={() =>
                                                                history.goBack()
                                                            }
                                                        >
                                                            {" "}
                                                            <i
                                                                className="fa fa-arrow-left"
                                                                aria-hidden="true"
                                                            >{""}</i>
                                                            <span className="value">
																Retour
															</span>
                                                        </h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="containerViewIn" className="containerViewIn">
                                        <div
                                            id="containerViewPost"
                                            className="containerViewPost"
                                        >
                                            <div
                                                id="containerViewPost1"
                                                className="containerViewPost1"
                                            >
                                                <div
                                                    id="containerViewPost2"
                                                    className="containerViewPost2"
                                                >
                                                    <div
                                                        id="containerViewPost3"
                                                        className="containerViewPost3 mt-4"
                                                    >
                                                        {dataFromService.loading ? (
                                                            dataFromService.repos.map((elt) => {
                                                                return (
                                                                    <Card
                                                                        key={elt.id}
                                                                        artist={elt}
                                                                        handleChangedFavorite={handleChangedFavorite}
                                                                        {...props}
                                                                    />
                                                                );
                                                            })
                                                        ) : (
                                                            <span id="progressSpinner">
												<CircularProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
											</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection/>
        </>
    );
};

export default Favorite;
