import React, {useEffect, useState} from "react";
import Headerd from "../HeaderSection/Headerd";
import {Link, useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from "@material-ui/core/Typography";
import FooterSection from "../FooterSection/Footer";
import requestsService from "../../services/request-service";
import {makeStyles} from "@material-ui/core/styles";
import {Appar, Box, Button, FormControl, FormHelperText, Input, InputLabel, Tab, Tabs, TextField} from "@material-ui/core";
import PropTypes from 'prop-types';
import userService from "../../services/users-service";
import Requests from "../Requests/Requests";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    root: {
        width: "100%",
        // maxWidth: "36ch",
        backgroundColor: theme.palette.background.paper,
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    inline: {
        display: "inline",
    },
    margin: {
        margin: theme.spacing(1),
    },
    couleur: {
        backgroundColor: "#6F62FF",
        margin: theme.spacing(1),
    }
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MyProfile = () => {

    const promise = requestsService.getAll();
    // const usersService = userService.changePassword("pp","p");
    const [data, setData] = useState({loading: false, repos: null});
    const [progress, setProgress] = useState(0);
    const [search, setSearch] = useState("");
    const [value, setValue] = React.useState(0);
    const [open, setModalOpen] = React.useState(false);
    const [isOpen, setOpen] = React.useState(false);
    const [isOpenDemande, setOpenDemande] = React.useState(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleOpenDemande = () => {
        setOpenDemande(true);
    };
    const handleCloseDemande = () => {
        setOpenDemande(false);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (data.repos === null || data.repos === undefined) {
            promise.then((dataResult) => {
                console.log("Wussp", dataResult);
                let publicVideos = [];
                let privateVideos = [];
                for (const vid of dataResult) {
                    if (vid.value.urlVideo !== "") {
                        if (vid.value.isPublic) {
                            publicVideos.push(vid)
                        } else {
                            privateVideos.push(vid)
                        }
                    }
                }
                setData({loading: true, repos: dataResult, publicVideos: publicVideos, privateVideos: privateVideos});
            });
        }
        progressSpinner();
    }, [data.repos]);

    const progressSpinner = () => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 10
            );
        }, 800);

        return () => {
            clearInterval(timer);
        };
    };

    const onOpenModal = () => {
        setOpen(!isOpen);
    };

    const updatePassword = (event) => {
        event.preventDefault()
        console.log("Form result", event.target.pswd.value);
        console.log("Form result", event.target.pswd1.value);
        console.log("Form result", event.target.pswd2.value);
        userService.changePassword(event.target.pswd.value, event.target.pswd1.value)
    }

    let history = useHistory();

    const handleSearch = (event) => setSearch(event.target.value);

    const classes = useStyles();

    return (
        <>
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up"/>
            </div>
            <div className="mainFav" style={{overflow: "hidden"}}>
                <Headerd
                    searchValue={search}
                    handleSearch={handleSearch}
                    imageData={"/img/logo-white.png"}
                />
                <div className="FavPage">
                    {/*<div className="mat-drawer-backdrop ng-star-inserted"></div>*/}
                    <div id="home" className="FavPageContainer">
                        <div className="FavPageContent">
                            <div className="PageContainer">
                                <div className="FavContainer">

                                    <h2 className="text-center mb-4">Mon Profil</h2>

                                    <div className="FavCard">
                                        <div className="FavCardContainer">
                                            <div className="FavCardContent">
                                                <Grid container spacing={3}>
                                                    <div className="mx-auto">
                                                        {/*<Button>Lien CGU</Button>*/}
                                                        <Button onClick={handleCloseDemande} color="primary">Mes Videos</Button>
                                                        <Button onClick={handleOpenDemande}>Mes Demandes</Button>
                                                        <Button onClick={handleOpen}>Changer Mot de passe</Button>
                                                        {/*<Link to="/favorite"><Button>Favories</Button></Link>*/}
                                                        {/*<Button color="secondary">Déconnexion</Button>*/}
                                                    </div>
                                                    {!isOpenDemande ? (<div className={classes.root}>
                                                        {/*<AppBar position="static">*/}
                                                        <Tabs color="secondary" value={value} onChange={handleChange} indicatorColor="secondary"
                                                              textColor="primary" centered aria-label="simple tabs example">
                                                            <Tab label="Publique" {...a11yProps(0)} />
                                                            <Tab label="Privée" {...a11yProps(1)} />
                                                        </Tabs>
                                                        {/*</AppBar>*/}
                                                        <TabPanel value={value} index={0}>
                                                            <div id="containerViewInx" className="containerViewInx">
                                                                <div
                                                                    id="containerViewPostx"
                                                                    className="containerViewPostx"
                                                                >
                                                                    <div
                                                                        id="containerViewPost1x"
                                                                        className="containerViewPost1x"
                                                                    >
                                                                        <div
                                                                            id="containerViewPost2x"
                                                                            className="containerViewPost2x"
                                                                        >
                                                                            <div
                                                                                id="containerViewPost3x"
                                                                                className="containerViewPost3x"
                                                                            >
                                                                                <Grid container spacing={1}>
                                                                                    {data.loading
                                                                                        ? data.publicVideos.map(
                                                                                            (elt, index) => {
                                                                                                return (
                                                                                                    <Grid xs={12} sm={4} key={index}>
                                                                                                        <video
                                                                                                            id="videoDetail"
                                                                                                            className="videoDetail"
                                                                                                            src={elt.value.urlVideo}
                                                                                                            controls
                                                                                                        >
                                                                                                            {" "}
                                                                                                        </video>
                                                                                                    </Grid>
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        : " "}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel value={value} index={1}>
                                                            <div id="containerViewInx" className="containerViewInx">
                                                                <div
                                                                    id="containerViewPostx"
                                                                    className="containerViewPostx"
                                                                >
                                                                    <div
                                                                        id="containerViewPost1x"
                                                                        className="containerViewPost1x"
                                                                    >
                                                                        <div
                                                                            id="containerViewPost2x"
                                                                            className="containerViewPost2x"
                                                                        >
                                                                            <div
                                                                                id="containerViewPost3x"
                                                                                className="containerViewPost3x"
                                                                            >
                                                                                <Grid container spacing={1}>
                                                                                    {data.loading
                                                                                        ? data.privateVideos.map(
                                                                                            (elt, index) => {
                                                                                                return (
                                                                                                    <Grid xs={12} sm={4} key={index}>
                                                                                                        <video
                                                                                                            id="videoDetail"
                                                                                                            className="videoDetail"
                                                                                                            src={elt.value.urlVideo}
                                                                                                            controls
                                                                                                        >
                                                                                                            {" "}
                                                                                                        </video>
                                                                                                    </Grid>
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        : "Aucune Vidéo"}
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                    </div>) : null}
                                                    {isOpenDemande ? (
                                                        <div className={classes.root}>
                                                            <Requests/>
                                                        </div>
                                                    ) : null}
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSection/>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className="px-5 py-5">
                            <form onSubmit={updatePassword} className={classes.root} noValidate autoComplete="off">
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12}>
                                        <h3 className="my-3 text-center">
                                            Changer Mot de passe
                                            <button onClick={handleClose} className="mr-3 float-right border-0">
                                                <i className="fas fa-times-circle text-danger">{""}</i>
                                            </button>
                                        </h3>
                                    </Grid>
                                    <Grid item md={7} xs={12} className="mx-auto">
                                        <FormControl fullWidth className={classes.margin}>
                                            <TextField name="pswd" type="password" id="outlined-basic" label="Ancien Mot de passe"
                                                       variant="outlined"/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={7} xs={12} className="mx-auto">
                                        <FormControl fullWidth className={classes.margin}>
                                            <TextField name="pswd1" id="outlined-basic2" label="Nouveau Mot de passe" variant="outlined"/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={7} xs={12} className="mx-auto">
                                        <FormControl fullWidth className={classes.margin}>
                                            <TextField name="pswd2" id="outlined-basic" label="Confirmer Mot de passe" variant="outlined"/>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={3} className="mx-auto">
                                        {/*<Button variant="contained" size="large" color="primary" disableElevation className={classes.couleur}>
                                            Enregistrer les modifications
                                        </Button>*/}
                                        <button type="submit" className="btn btn-block btn-tertiary mb-4">
                                            ENREGISTRER
                                        </button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default MyProfile;
