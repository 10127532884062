import React, {useState, useEffect} from "react";
import db from "../../config/auth-firebase";
// import SearchDashboard from "./../SearchDashboard/SearchDashboard";

const artistsRef = db.collection("artists");
const initData = {
    heading: "Le lien avec vos célébrités",
    content:
        "Only2U est une plate-forme où vous pouvez réserver des vidéos personnalisées de vos célébrités préférées.",
    headingSlug: "",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
    heroThumb: "/img/h3.png",
};

const HeroSection = (props) => {
    const [data, setData] = useState({loading: false, repos: []});

    useEffect(() => {
        // console.log("props.data => Component HeroSection");
        // console.log(props.searchValue);
        setData({loading: true, repos: initData});
        // SearchByName(props.searchValue)
        // Will return all Springfields
        if (props.searchValue !== "") {
            artistsRef
                .orderBy("lastname")
                .orderBy("priority", "desc")
                .startAt(props.searchValue)
                .get()
                .then((querySnapshot) => {
                    var result = [];
                    querySnapshot.docs.map((doc) =>
                        result.push({id: doc.id, value: doc.data()})
                    );
                    //console.log(result);
                    // setDataSearching({
                    //   loading: true,
                    //   repos: result,
                    // });
                });
        }
    }, [props.searchValue]);

    return (
        <section
            id="home"
            className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center mt-0 p-0"
        >
            <div className="container mt-0 p-0">
                <div className="row welcome-texte mt-0 p-0">
                    {/* Welcome Intro Start */}
                    <div id="un" className="col-12 col-md-7 col-lg-6 mt-0 p-0">
                        <div className="welcome-intro">
                            <h2 className="text-white">{data.repos.heading}</h2>
                            <p className="text-white my-1">{data.repos.content}</p>
                            <div className="button-group store-buttons d-flex ml-5 pl-4">
                                <a href="https://apps.apple.com/sa/app/only2u/id1560692164">
                                    <img src={data.repos.appleImg} alt=""/>
                                </a>
                            </div>
                            {/* <span className="d-inline-block text-white fw-3 font-italic mt-3">{data.headingSlug}</span> */}
                        </div>
                    </div>
                    <div id="deux" className="col-12 col-md-5 col-lg-6">
                        {/* Welcome Thumb */}
                        <div className="welcome-thumb mx-auto">
                            {/*<img src={data.heroThumb} alt="" width="250" height="100" />*/}
                            <video id="vid" className="bon" src="/img/c.mp4" controls>
                                {" "}
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="shape-bottom">
                <svg viewBox="0 0 1920 310" version="1.1" xmlns="" xmlnsXlink="" className="svg replaced-svg">
                    <title>sApp Shape</title>
                    <desc>Created with Sketch</desc>
                    <defs />
                    <g id="sApp-Landing-Page" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
                            <path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395" id="sApp-v1.0" />
                        </g>
                    </g>
                </svg>
            </div> */}
        </section>
    );
};

export default HeroSection;
